import React from "react";
import { Col, Nav, Tab, Row } from "react-bootstrap";
import { useCurrentUser, useUserRole } from "../custom_hooks/user";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";
import UpdateLocation from "./profil/UpdateLocation";
import AuthCode from "./common/AuthCode";
import { useTranslation } from "react-i18next";
import UpdatePassword from "./profil/UpdatePassword";
import UpdateProfil from "./profil/UpdateProfil";
import { LabeledRow } from "./common/LabeledRow";
import { GeoTest } from "./common/GeoTest";
import { UserRole } from "../constants";
import { DeleteUserDangerZone } from "./profil/DeleteUserDangerZone";

function Profil() {
  const currentUser = useCurrentUser();
  const [t] = useTranslation();
  const role = useUserRole();

  if (!currentUser) return <CommonSpinner />;

  return (
    <Col md={12}>
      <h1>{t("update_profil.title")}</h1>
      <AuthCode>
        <Tab.Container
          id="left-tabs"
          transition={false}
          defaultActiveKey="first"
        >
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    {t("update_profil.header")}
                  </Nav.Link>
                </Nav.Item>
                {role === UserRole.NAA && (
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      {t("update_location.header")}
                    </Nav.Link>
                  </Nav.Item>
                )}
                {role !== UserRole.HTK && (
                  <Nav.Item>
                    <Nav.Link eventKey="passwordChanger">
                      {t("reset_password.header")}
                    </Nav.Link>
                  </Nav.Item>
                )}
                {role === UserRole.NAA && (
                  <Nav.Item>
                    <Nav.Link eventKey="accountSettings">
                      {t("update_profil.account_settings")}
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <UpdateProfil />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <UpdateLocation />
                  {currentUser.location?.geo_code && (
                    <LabeledRow label={t("location.geo_code")}>
                      <p>{currentUser.location.geo_code}</p>
                      <p>
                        <GeoTest geo_code={currentUser.location.geo_code}>
                          {t("location.geo_code_test")}
                        </GeoTest>
                        <br />
                        <br />
                        {t("location.geo_code_explain")}
                      </p>
                    </LabeledRow>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="passwordChanger">
                  <UpdatePassword />
                </Tab.Pane>
                <Tab.Pane eventKey="accountSettings">
                  <DeleteUserDangerZone />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </AuthCode>
    </Col>
  );
}

export default Profil;
