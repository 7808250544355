import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useApiResponse } from "../../custom_hooks/hooks";
import {
  displayDate,
  displayDateDayMonth,
  displayHourMinute,
  validateDate,
} from "../../dateFunctions";
import { formatBytes } from "../../shared";
import ApiResponseLayer from "../common/ApiResponseLayer";

export const FilesTable = ({ preview, limit }) => {
  const params = limit ? { limit: limit } : null;
  const apiResult = useApiResponse("/files", "get", params);
  const [response] = apiResult;
  const [files, setFiles] = useState(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (!response) return;
    setFiles(response);
  }, [response]);

  return (
    <>
      <ApiResponseLayer apiResult={apiResult} />
      <Table striped hover>
        {!preview && (
          <thead>
            <tr>
              <th>{t("files.updated_at")}</th>
              <th>{t("naa")}</th>
              <th>{t("files.size")}</th>
              <th>{t("files.created_at")}</th>
              <th className="text-right">{t("files.expire_at")}</th>
            </tr>
          </thead>
        )}
        <tbody>
          {files &&
            files.map((folder) => {
              const {
                user_id,
                createdAt,
                expireAt,
                size,
                user_name,
                filesUpdatedAt,
              } = folder;
              if (!user_id) return null;

              const ua = validateDate(filesUpdatedAt);
              const updatedTime = displayHourMinute(ua);
              const updatedDay = displayDateDayMonth(ua);
              const folderUrl = `/files/${folder.user_id}`;
              return (
                <tr key={folder.id}>
                  <td>
                    <Link to={folderUrl}>
                      {updatedDay} {updatedTime}
                    </Link>
                  </td>
                  <td>
                    <Link to={folderUrl}>{user_name}</Link>
                  </td>
                  <td>{formatBytes(size)}</td>
                  {!preview && (
                    <>
                      <td>{displayDate(validateDate(createdAt))}</td>
                      <td>
                        {!!expireAt ? displayDate(validateDate(expireAt)) : "-"}
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

FilesTable.defaultProps = { preview: false };
