import React, { useEffect } from "react";
import HtkNewMembersDecline from "./HtkNewMembersDecline";
import { DashboardElement } from "./DashboardElement";
import { confirmNewMember, getRequested } from "../../api/user";
import { Accordion, Card, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GeoTest } from "../common/GeoTest";
import { ContextAwareToggle } from "../common/ContextAwareToggle";
import { ActionButton } from "../appointment/ActionButton";
import { useTranslation } from "react-i18next";

const attribute_keys = [
  "clinic_name",
  "clinic_email",
  "street",
  "postal_code",
  "city",
  "phone",
  "fax",
  "geo_code",
  "message",
];

const NewMembers = () => {
  const { newMembers } = useSelector((state) => state.appState);
  const [t] = useTranslation();
  useEffect(() => {
    getRequested();
  }, []);

  const onConfirmClick = (id) => {
    confirmNewMember(id);
  };

  const createMemberCard = (member, index) => {
    const itemKey = "card" + (index + 1).toString();
    const { id, attributes } = member;
    const { title, email, status, first_name, last_name, user_role } =
      attributes;
    const icon =
      status === "invited"
        ? "fa-clinic-medical"
        : "fa-user-md arrow_rotate_active arrow_rotate";

    return (
      <Card key={`newmember-${index}`}>
        <Card.Header className="event-list-item">
          <ContextAwareToggle eventKey={itemKey}>
            <div>
              <i type="fas" className={`fa ${icon} new-member`} />
              &nbsp;
              {email}
            </div>
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={itemKey}>
          <Card.Body className="m-0 p-0">
            <div className="box-head">{t(user_role)}</div>

            <Table striped>
              <tbody>
                <tr>
                  <td>{t("title")}</td>
                  <td>{title}</td>
                </tr>
                <tr>
                  <td>{t("first_name")}</td>
                  <td>{first_name}</td>
                </tr>
                <tr>
                  <td>{t("last_name")}</td>
                  <td>{last_name}</td>
                </tr>
              </tbody>
            </Table>

            {status !== "invited" && (
              <>
                <div className="box-head">Praxisdaten</div>
                <Table striped>
                  <tbody>
                    {attribute_keys.map((key) => {
                      const value = attributes.location[key];
                      return (
                        <tr key={key}>
                          <td>{t(`location.${key}`)}</td>
                          <td>
                            {key !== "geo_code" ? (
                              value
                            ) : (
                              <GeoTest geo_code={value}>
                                <i className="fa fa-eye"></i>
                              </GeoTest>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="rowflex space-between margin-bottom">
                  <ActionButton
                    onClick={() => onConfirmClick(id)}
                    title="accept"
                  />
                  <HtkNewMembersDecline id={id} />
                </div>
              </>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  return (
    <DashboardElement
      title={t("members.new_members.title")}
      icon="user-plus"
      description={t("members.new_members.description")}
    >
      {!newMembers.length && <p>{t("members.new_members.none")}</p>}
      <Accordion className="p-0">{newMembers.map(createMemberCard)}</Accordion>
    </DashboardElement>
  );
};

export default NewMembers;
