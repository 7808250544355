import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import { Marker, Popup } from "react-leaflet";
import MemberContent from "./MemberContent";

function MemberMarker({ center, markerContent, openPopup }) {
  const markerRef = useRef(null);

  useEffect(() => {
    if (openPopup) markerRef.current.openPopup();
  }, [openPopup]);

  return (
    <Marker ref={markerRef} position={center}>
      <Popup>
        <div className="marker-popup">
          <MemberContent markerContent={markerContent} />
        </div>
      </Popup>
    </Marker>
  );
}

MemberMarker.defaultProps = {
  openPopup: false,
};
MemberMarker.propTypes = {
  center: PropTypes.any,
  markerContent: PropTypes.object,
  openPopup: PropTypes.bool,
};

export default MemberMarker;
