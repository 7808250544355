import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";
import * as React from "react";
import { Alert } from "react-bootstrap";

export const ApiResponseLayer = ({ apiResult, children }) => {
  const [result, loading, error] = apiResult;
  return (
    <div>
      {error && (
        <Alert variant="danger" transition={false}>
          {error.message}
        </Alert>
      )}
      {loading ? <CommonSpinner /> : children}
    </div>
  );
};

export default ApiResponseLayer;
