import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { canEditFiles } from "../../shared";
import { FilesTable } from "../files/FilesTable";
import { DashboardElement } from "./DashboardElement";

export const TransferElement = () => {
  const [t] = useTranslation();

  if (!canEditFiles()) return null;

  return (
    <DashboardElement
      title={t("files.latest")}
      boxColor="blue"
      description={t("files.description")}
    >
      <FilesTable preview={true} limit={10} />
      <Link to="/files">{t("show_all")}</Link>
    </DashboardElement>
  );
};
export default TransferElement;
