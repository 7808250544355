import React from "react";
import { Row } from "react-bootstrap";
import AllEvents from "./AllEvents";
import CalendarList from "./CalendarList";
import CanceledEvents from "./CanceledEvents";
import NewAppointments from "./NewAppointments";
import NewMembers from "./NewMembers";
import PasswordReset from "./PasswordReset";
import SettingsElement from "./SettingsElement";
import TransferElement from "./TransferElement";

const HtkDashboardIndex = () => {
  const { enable_appointment_scheduler } = window.ITREF.config;
  return (
    <Row className="dashboard">
      <TransferElement />
      {enable_appointment_scheduler && (
        <>
          <NewAppointments />
          <CanceledEvents />
        </>
      )}
      <NewMembers />
      <PasswordReset />
      {enable_appointment_scheduler && (
        <>
          <CalendarList />
          <AllEvents />
        </>
      )}
      <SettingsElement />
    </Row>
  );
};

export default HtkDashboardIndex;
