import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  freeEvent,
  updateEventStatusAndReloadCalendars,
} from "../../api/event";
import { AppointmentStatus, UserRole } from "../../constants";
import { EventStatus } from "../../eventStatus";
import { useUserRole } from "../../custom_hooks/user";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";
import { IconButton } from "../common/IconButton";
import {
  hideConfirmationModal,
  showConfirmationModal,
} from "../../actions/app";
import { useDispatch } from "react-redux";
import { remoteEventStatusChange } from "../../actions/dashboard";

export const AppointmentQuickActions = ({ appointment }) => {
  const role = useUserRole();
  const [t] = useTranslation();
  const [updating, setUpdating] = useState(false);
  const dispatch = useDispatch();
  const { status, id } = appointment;

  const updateStatus = (newStatus) => {
    setUpdating(true);
    updateEventStatusAndReloadCalendars(id, newStatus)
      .then(() => setUpdating(false))
      .catch(() => setUpdating(false));
  };

  const acceptAppointment = (e) => {
    cancelClick(e);
    updateStatus(AppointmentStatus.ACCEPTED);
  };

  const freeAppointment = (e) => {
    cancelClick(e);
    dispatch(
      showConfirmationModal({
        title: t(`dashboard.free.action_title`),
        description: t(`dashboard.free.confirm_desc`),
        proceed: proceedFreeCallback,
      })
    );
  };

  const cancelAndFreeAppointment = (e) => {
    cancelClick(e);
    const confirmLabel =
      role === UserRole.NAA
        ? "user_cancel_confirm_desc"
        : "cancel_confirm_desc";

    dispatch(
      showConfirmationModal({
        title: t(`dashboard.cancellation.action_title`),
        description: t(`dashboard.cancellation.${confirmLabel}`),
        proceed: proceedFreeCallback,
      })
    );
  };

  const proceedFreeCallback = (value) => {
    dispatch(hideConfirmationModal());
    if (value) freeAndReload();
  };

  const freeAndReload = () => {
    freeEvent(id).then((res) => {
      dispatch(remoteEventStatusChange(res.data.data));
    });
  };

  const cancelClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  let buttons = [];

  if (role !== UserRole.NAA) {
    // every user expect NAA
    if (status === EventStatus.REQUESTED) {
      buttons.push(
        <IconButton
          key="check"
          onClick={acceptAppointment}
          icon="check-circle"
          desc="dashboard.confirmation.accept_action_desc"
          title="dashboard.confirmation.accept_action_title"
        />
      );
    }

    if (status === EventStatus.ACCEPTED) {
      buttons.push(
        <IconButton
          key="accepted"
          onClick={cancelAndFreeAppointment}
          icon="times-circle"
          desc="dashboard.cancellation.action_desc"
          title="dashboard.cancellation.action_title"
        />
      );
    }

    if (status === EventStatus.CANCELED) {
      buttons.push(
        <IconButton
          key="redo"
          onClick={freeAppointment}
          icon="redo"
          desc="dashboard.free.action_desc"
          title="dashboard.free.action_title"
        />
      );
    }
  }

  return (
    <div className="rowflex justified">
      {updating ? <CommonSpinner /> : buttons}
    </div>
  );
};
