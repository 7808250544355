import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { hideEventModal, updateCurrentEvent } from "../../actions/app";
import {
  freeEvent,
  unblockEvent,
  updateEventStatusAndReloadCalendars,
  cancelEvent,
} from "../../api/event";
import { AppointmentStatus, UserRole } from "../../constants";
import { EventStatus } from "../../eventStatus";
import { useCurrentEvent } from "../../custom_hooks/events";
import { useUserRole } from "../../custom_hooks/user";
import { ActionButton } from "../appointment/ActionButton";
import { AppointmentCancelOptions } from "../appointment/AppointmentCancelOptions";

export const EventActions = () => {
  const currentEvent = useCurrentEvent();
  const user_role = useUserRole();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");

  function handleChange(event) {
    setReason(event.target.value);
  }

  if (!currentEvent) return null;

  const { status, id } = currentEvent;

  const changeStatus = (newStatus) => {
    updateEventStatusAndReloadCalendars(id, newStatus).then(hideModal);
  };

  const hideModal = () => dispatch(hideEventModal());

  const onCancel = () => {
    cancelEvent(id).then(hideModal);
  };

  const onRetract = () => {
    freeEvent(id).then((res) => {
      hideModal();
      dispatch(updateCurrentEvent(null));
    });
  };

  const onCancelReasoned = () => {
    cancelEvent(id, reason).then(hideModal);
  };

  const onUnblock = () => unblockEvent(currentEvent.id).then(hideModal);

  const onAccept = () => changeStatus(AppointmentStatus.ACCEPTED);

  if (user_role === UserRole.NAA) {
    switch (status) {
      case EventStatus.REQUESTED:
        return <ActionButton onClick={onRetract} title="appointments.cancel" />;
      case EventStatus.ACCEPTED:
        return (
          <div>
            <div className="form-group">
              <h4>{t("appointments.cancellation")}</h4>
              <p className="small">
                {t("appointments.cancel_reason_description")}
              </p>
              <textarea
                onChange={(event) => handleChange(event)}
                className="form-control"
                rows="2"
              ></textarea>
              <br />
              <ActionButton
                disabled={!reason}
                onClick={onCancelReasoned}
                title="appointments.cancel"
              />
            </div>
          </div>
        );
    }
  } else {
    // WAA HTK and TTK
    switch (status) {
      case EventStatus.CREATED:
        return (
          <ActionButton
            desc="appointments.cancellation_description"
            title="appointments.reject_and_delete"
            onClick={onCancel}
          />
        );
      case EventStatus.REQUESTED:
        return (
          <div>
            <h4>{t("appointments.acceptance")}</h4>
            <Row>
              <Col md={12}>
                <ActionButton
                  onClick={onAccept}
                  title="accept"
                  desc="appointments.acceptance_description"
                />
              </Col>
            </Row>
            <AppointmentCancelOptions
              onCancel={onCancel}
              onRetract={onRetract}
            />
          </div>
        );
      case EventStatus.ACCEPTED:
        return (
          <AppointmentCancelOptions onCancel={onCancel} onRetract={onRetract} />
        );

      case EventStatus.CANCELED:
        return (
          <div className="margin-bottom">
            <h4>{t("appointments.further_actions")}</h4>
            <Row>
              <Col md={12}>
                <ActionButton
                  onClick={onRetract}
                  title="appointments.free_again"
                />
              </Col>
            </Row>
          </div>
        );
      case EventStatus.BLOCKED:
        if (user_role === UserRole.HTK) {
          return <ActionButton title="appointments.free" onClick={onUnblock} />;
        }
        break;
    }
  }
  return <p className="small">{t("appointments.no_action_available")}</p>;
};

EventActions.propTypes = {
  id: PropTypes.any,
  status: PropTypes.string,
  user_role: PropTypes.string,
};
