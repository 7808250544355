import React from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { updateCurrentUser } from "../../actions/dashboard";
import { logOut } from "../../api/user";
import { UserRole } from "../../constants";
import { useCurrentUser } from "../../custom_hooks/user";
import { store } from "../../store/store";

const UserQuickLinks = () => {
  const currentUser = useCurrentUser();
  const [t] = useTranslation();

  const handleLogoutButton = () => {
    store.dispatch(updateCurrentUser());
    logOut();
  };

  if (!currentUser) return null;

  const userFileLink =
    currentUser.user_role === UserRole.NAA
      ? `/files/${currentUser.id}`
      : `/files`;

  return (
    <NavDropdown title={currentUser.email} id="collasible-nav-dropdown">
      {window.ITREF.config.enable_appointment_scheduler && (
        <LinkContainer to="/dashboard">
          <NavDropdown.Item>
            <span>
              <i type="fas" className="fa fa-tachometer-alt dark-green"></i>
            </span>
            {t("dashboard.title")}
          </NavDropdown.Item>
        </LinkContainer>
      )}
      <LinkContainer to="/profil">
        <NavDropdown.Item>
          <span>
            <i type="fas" className="fa fa-user dark-green"></i>
          </span>
          {t("profil")}
        </NavDropdown.Item>
      </LinkContainer>

      {window.ITREF.config.enable_file_transfer && (
        <LinkContainer to={userFileLink}>
          <NavDropdown.Item>
            <span>
              <i type="fas" className="fa fa-cloud-download-alt dark-green"></i>
              {t("data_transfer")}
            </span>
          </NavDropdown.Item>
        </LinkContainer>
      )}

      <NavDropdown.Divider />

      <NavDropdown.Item
        onClick={(e) => {
          e.preventDefault(), handleLogoutButton();
        }}
      >
        <span>
          <i type="fas" className="fa fa-sign-out-alt dark-green"></i>
        </span>
        {t("login.logout")}
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default UserQuickLinks;
