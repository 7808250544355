import React from "react";
import { useTranslation } from "react-i18next";

function FooterNavigation() {
  const [t] = useTranslation();

  return (
    <footer>
      <hr />
      <div className="container">
        <ul>
          <li>
            <a
              href={
                "https://www.lmu-klinikum.de/scrivito/imprint?homepage=4c9dc47213c27567"
              }
            >
              {t("imprint")}
            </a>
          </li>
          <li>
            <a href={"https://www.lmu-klinikum.de/data_safety"}>
              {t("data_safety")}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default FooterNavigation;
