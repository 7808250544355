import React, { useEffect, useState } from "react";
import {
  Alert,
  Col,
  Row,
  Table,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { updateShowModal } from "../../actions/app";
import {
  deleteFile,
  getDownloadLink,
  getUser,
  getUserUploadLink,
} from "../../api/user";
import { ModalID, UserRole } from "../../constants";
import { useWindowFocus } from "../../custom_hooks/files";
import { useApiResponse } from "../../custom_hooks/hooks";
import { useCurrentUser, useUserRole } from "../../custom_hooks/user";
import { displayDateDayMonth, validateDate } from "../../dateFunctions";
import { extractUsername, formatBytes } from "../../shared";
import { store } from "../../store/store";
import { ActionButton } from "../appointment/ActionButton";
import ApiResponseLayer from "../common/ApiResponseLayer";
import { ContentContainer } from "../common/ContentContainer";
import { IconButton } from "../common/IconButton";
import { Informers } from "./Informers";
import PatientDataModal from "./PatientDataModal";

export const FileTransfer = ({ id }) => {
  const [t] = useTranslation();
  const [files, setFiles] = useState([]);
  const role = useUserRole();
  const [stateMessage, setStateMessage] = useState("");
  const [ownerDetails, setOwnerDetails] = useState(null);
  const { userId } = useParams();

  const windowFocused = useWindowFocus();
  const { showModal } = useSelector((state) => state.appState);
  const [showing, setShowing] = useState(false);
  const [uploadLink, setUploadLink] = useState("");
  const [cacheBuster, setCacheBuster] = useState("");

  const wantedId = userId || id;

  const apiResult = useApiResponse(
    wantedId ? `/users/${wantedId}/files.json?${cacheBuster}` : ""
  );
  const [response, loading] = apiResult;

  useEffect(() => {
    if (response) setFiles(response.items);
  }, [response]);

  useEffect(() => {
    loadUserDetails();
  }, [wantedId]);

  useEffect(() => {
    if (windowFocused) loadFiles();
  }, [windowFocused]);

  useEffect(() => {
    if (showing && !showModal.includes(ModalID.PATIENT_DATA)) {
      setCacheBuster(Math.random() * 10000);
      setShowing(false);
    }
  }, [showModal]);

  const loadFiles = () => {
    setCacheBuster(Math.random() * 10000);
    setStateMessage("");
  };

  const loadUserDetails = async () => {
    if (!wantedId) return;
    const response = await getUser(wantedId);
    setOwnerDetails(response.data.data);
  };

  const openUploadWindow = () => {
    getUserUploadLink(wantedId).then((res) => {
      let newWindow = window.open(res.data.url, "_blank");
      if (!newWindow) {
        setUploadLink(res.data.url);
      }
    });
  };

  const fileDownload = async (nodeId) => {
    const response = await getDownloadLink(wantedId, nodeId);
    window.location.href = response.data.url;
  };

  const fileDelete = async (nodeId) => {
    setStateMessage("file_deleting");
    await deleteFile(wantedId, nodeId);
    setStateMessage("file_deleted");
    const newFiles = files.filter((file) => file.id != nodeId);
    setFiles(newFiles);
  };

  let filesTitle = t("files.my_files");
  if (role !== "naa" && ownerDetails?.attributes) {
    filesTitle =
      t("files.uploaded_files_for") + " " + extractUsername(ownerDetails);
  }

  let backlink = <>&nbsp;</>;
  if (role !== "naa" || window.ITREF.config.enable_appointment_scheduler) {
    backlink = (
      <Link to={`/files`} className="small">
        &laquo; {t("back_to_files_overview")}
      </Link>
    );
  }

  const openModal = () => {
    store.dispatch(updateShowModal(ModalID.PATIENT_DATA));
    setShowing(true);
  };

  const renderOverlay = (props) => {
    const newStyle = { opacity: 1 };
    Object.keys(props.style).forEach((kley) => {
      newStyle[kley] = props.style[kley];
    });

    return (
      <Tooltip id="button-tooltip" {...props} style={newStyle}>
        {t("files.upload_description")}
      </Tooltip>
    );
  };

  return (
    <ContentContainer title={t("data_transfer")} backlink={backlink}>
      <p className="small">{t("files.transfer_description")}</p>

      <Row className="event-series-index">
        <Col sm={3}>
          {role === UserRole.NAA && (
            <div className="margin-bottom">
              <ActionButton title="patient_data.add_form" onClick={openModal} />
              <PatientDataModal />
            </div>
          )}
          <Informers ownerDetails={ownerDetails} />
        </Col>

        <Col sm={9}>
          <div className="margin-bottom rowflex space-between">
            <div className="rowflex">
              <ActionButton
                icon="file-upload"
                onClick={openUploadWindow}
                title="files.upload_new"
              />
              &nbsp;
              <OverlayTrigger
                transition={false}
                placement="top"
                overlay={renderOverlay}
              >
                <i className="fas fa-question-circle"></i>
              </OverlayTrigger>
              <br />
              {uploadLink && (
                <p className="small">
                  <a href={uploadLink} target="_blank">
                    {t("files.click_if_blocked")}
                  </a>
                </p>
              )}
            </div>
          </div>

          <h3>{filesTitle}</h3>

          <ApiResponseLayer apiResult={apiResult} />

          {stateMessage && (
            <div className="alert">
              <Alert
                variant={stateMessage == "file_deleting" ? "info" : "success"}
                transition={false}
              >
                {t(stateMessage)}
              </Alert>
            </div>
          )}

          {files && !!files.length && (
            <Table striped>
              <thead>
                <tr>
                  <th>{t("files.file_name")}</th>
                  <th>{t("files.created_at")}</th>
                  <th>{t("files.expire_at")}</th>
                  <th>{t("files.file_type")}</th>
                  <th>{t("files.size")}</th>
                  <th>{t("download")}</th>
                  <th>{t("delete")}</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file) => {
                  return (
                    <tr key={file.id}>
                      <td>{file.name}</td>
                      <td>
                        {displayDateDayMonth(validateDate(file.createdAt))}
                      </td>
                      <td>
                        {file.expireAt ? (
                          displayDateDayMonth(validateDate(file.expireAt))
                        ) : (
                          <p>∞</p>
                        )}
                      </td>
                      <td>{file.fileType}</td>
                      <td>{formatBytes(file.size)}</td>
                      <td>
                        <IconButton
                          icon="download"
                          alt="files.download_to_computer"
                          title="files.download_to_computer"
                          onClick={() => fileDownload(file.id)}
                        />
                      </td>
                      <td>
                        <IconButton
                          icon="trash"
                          alt="delete"
                          title="delete"
                          onClick={() => fileDelete(file.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}

          {!loading && !files.length && <p>{t("files.none_uploaded")}</p>}
        </Col>
      </Row>
    </ContentContainer>
  );
};

FileTransfer.defaultProps = {
  id: 0,
};

export default FileTransfer;
