import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { iconByStatus } from "../../eventStatus";

export const StatusIndicator = ({ status }) => {
  const [t] = useTranslation();
  return (
    <div className="status-indicator" title={t("event.status." + status)}>
      <i className={`fa fa-${iconByStatus[status]}`}></i>
    </div>
  );
};

StatusIndicator.propTypes = {
  size: PropTypes.number,
  status: PropTypes.string,
};
