import React from "react";
import { useTranslation } from "react-i18next";
import { useUserRole } from "../../custom_hooks/user";
import { canEditFiles } from "../../shared";
import { ContentContainer } from "../common/ContentContainer";
import { FilesTable } from "./FilesTable";

export const FileIndex = () => {
  const [t] = useTranslation();
  const role = useUserRole();
  if (role && canEditFiles()) {
    return (
      <ContentContainer title={t("files.folders")}>
        <FilesTable />
      </ContentContainer>
    );
  }
  return <p>Zugriff verweigert</p>;
};

export default FileIndex;
