import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { getI18n, useTranslation } from "react-i18next";
import { getSpecialField } from "../../shared";
import { SpecialFieldSelect } from "../common/SpecialFieldSelect";
import { LabeledRow } from "./../common/LabeledRow";
import { CustomInput } from "./CustomInput";
import { UserFormFields } from "./UserFormFields";

export const PatientDataFormFields = ({ formik }) => {
  const [t] = useTranslation();

  const { values } = formik;

  return (
    <>
      <LabeledRow label={t("patient_data.special_field")}>
        <SpecialFieldSelect
          onChange={(v) => {
            formik.setFieldValue("special_field", getSpecialField(v).title);
          }}
        />
      </LabeledRow>
      <UserFormFields formik={formik} />
      <LabeledRow label={t("location.patient_address")} required={true}>
        <input
          defaultValue={formik.values.patient_address}
          name="patient_address"
          className="form-control"
          onChange={formik.handleChange}
        />
      </LabeledRow>
      <LabeledRow label={t("phone")} required={true}>
        <input
          defaultValue={formik.values.phone}
          name="phone"
          className="form-control"
          onChange={formik.handleChange}
        />
      </LabeledRow>
      <LabeledRow label={t("birthday")} required={true}>
        <ReactDatePicker
          name="birthday"
          className="form-control"
          showTimeSelect={false}
          dateFormat="dd.MM.yyyy"
          selected={values.birthday}
          onChange={(val) =>
            formik.setFieldValue("birthday", val.setHours(12, 0, 0, 0))
          }
        />
      </LabeledRow>

      <LabeledRow label={t("patient_data.urgency")}>
        {Object.entries(getI18n().t("patient_data.urgencies")).map(
          ([key, value]) => {
            return (
              <span className="nowrap" key={key}>
                <input
                  id={key}
                  name={key}
                  type="checkbox"
                  onChange={(e) => {
                    formik.setFieldValue(key, e.currentTarget.checked);
                  }}
                  defaultChecked={values[key]}
                />
                <label htmlFor={key}>{value}</label>
              </span>
            );
          }
        )}
      </LabeledRow>

      <LabeledRow label={t("patient_data.insurance_type")}>
        <input
          defaultChecked={true}
          name="insurance_type"
          id="statutory"
          type="radio"
          onChange={() => formik.setFieldValue("insurance_type", "statutory")}
          value="statutory"
        />
        &nbsp;
        <label htmlFor="statutory">
          {t("patient_data.insurance_types.statutory")}
        </label>
        <input
          name="insurance_type"
          id="private"
          type="radio"
          onChange={() => formik.setFieldValue("insurance_type", "private")}
          value="private"
        />
        &nbsp;
        <label htmlFor="private">
          {t("patient_data.insurance_types.private")}
        </label>
      </LabeledRow>

      {["insurance_with_number", "reason_for_intro", "diagnosis"].map((key) => (
        <LabeledRow
          label={t(`patient_data.${key}`)}
          required={key === "insurance_with_number"}
          key={key}
        >
          <CustomInput name={key} formik={formik} />
        </LabeledRow>
      ))}

      {["sypnosis", "question"].map((key) => (
        <LabeledRow
          label={t(`patient_data.${key}_desc`)}
          required={key === "question"}
          key={key}
        >
          <CustomInput name={key} formik={formik} type="textarea" />
        </LabeledRow>
      ))}
    </>
  );
};

PatientDataFormFields.propTypes = { formik: PropTypes.object };
