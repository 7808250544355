import React from 'react'
import { useTranslation } from "react-i18next";
import { SelectOption, AnsbUser } from "../../types";

interface PropDefs {
  items: SelectOption[]
  onChange: (value: number) => void
  placeholder: string
}

export const Select = ({ onChange, items, placeholder }: PropDefs) => {
  const [t] = useTranslation();
  return (
    <select
      className="form-control select"
      defaultValue={0}
      onChange={(e: any) => onChange(e.currentTarget.value)}
    >
      <option disabled value={0}>
        {t(placeholder)}
      </option>
      {items.map((item) => (
        <option key={item.id} value={item.id}>
          {item.title}
        </option>
      ))}
    </select>
  );
};

export default Select
