import React from "react";
import { useTranslation } from "react-i18next";
import { EventStatus } from "../../eventStatus";
import { DashboardElement } from "./DashboardElement";
import { FilteredEventList } from "./FilteredEventList";

function CanceledEvents() {
  const [t] = useTranslation();

  return (
    <DashboardElement
      icon="calendar-minus"
      title={t("dashboard.canceled_events")}
      description={t("dashboard.canceled_events_desc")}
      boxColor="red"
    >
      <FilteredEventList
        params={{ status: EventStatus.CANCELED, entered: true }}
      />
    </DashboardElement>
  );
}

export default CanceledEvents;
