import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function NavigationBar({ children }) {
  return (
    <Navbar expand="lg" sticky="top" className="navbar">
      <Container>
        <Navbar.Brand className="navbar-logo">
          <Link to="/">
            <img
              src={window.ITREF.config.logo}
              alt={window.ITREF.config.site_name + " | Home"}
              title={window.ITREF.config.site_name + " | Home"}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          {children}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
