import PropTypes from "prop-types";
import React from "react";
import { slimURL } from "../../shared";

function MemberContent({ markerContent }) {
  const {
    clinic_name,
    street,
    city,
    phone,
    clinic_email,
    postal_code,
    show_contact_details,
    website,
  } = markerContent.attributes;
  return (
    <div className="member-content">
      <h4>{clinic_name}</h4>
      <p>
        {street}
        <br />
        {postal_code} {city}
      </p>
      {show_contact_details && (
        <ul>
          {phone && (
            <li>
              <i className="fas fa-phone"></i>
              &nbsp;{phone}
            </li>
          )}

          {clinic_email && (
            <li>
              <i className="fas fa-envelope"></i>
              &nbsp;
              <a href={`mailto:${clinic_email}`}>{clinic_email}</a>
            </li>
          )}

          {website && (
            <li>
              <i className="fas fa-external-link-square-alt"></i>
              &nbsp;
              <a href={website} target="_blank">
                {slimURL(website)}
              </a>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

MemberContent.propTypes = {
  markerContent: PropTypes.object.isRequired,
};

export default MemberContent;
