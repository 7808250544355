import { updateCalendars } from "../actions/dashboard";
import ax from "../axios";
import { store } from "../store/store";

/**
 * Loads calendars and updates redux store on success
 */
export const getCalendars = () => {
  ax.get("/calendars").then((response) => {
    const calendars = response.data.data;
    store.dispatch(updateCalendars(calendars));
  });
};

/**
 * Loads calendar details
 */
export const getCalendar = (id) => {
  return ax.get(`/calendars/${id}`);
};

/**
 * Updates attributes of a calendar
 * @param {number} id
 * @param {object} data
 * @returns Promise
 */
export const updateCalendar = (id, data) => {
  return ax.put(`/calendars/${id}`, data);
};

/**
 * Deletes a calendar if it is void of events
 * @param {number} id
 * @returns
 */
export const deleteCalendar = (id) => {
  return ax.delete(`/calendars/${id}`);
};

/**
 * Create a new calendar with title and permissioned ttk ids in userIds
 * @param {string} title
 * @param {array} userIds
 * @returns Promise
 */

export const createCalendar = (params, userIds) => {
  return ax.post(`/calendars`, { ...params, user_ids: userIds });
};

/**
 * Update provisioned users for calendar
 * @param {number|string} id | calendar id to update
 * @param {array} userIds | list of ids of users that may access this calendar
 * @returns Promise with response from calendars#permissions
 */
export const permitUsers = (id, userIds) => {
  return ax.patch(`/calendars/${id}/permit`, { user_ids: userIds });
};
