import {
  encryptAppointment,
  createAppointmentEncryptionObj,
  getJsonContent,
} from "../actions/encryption";
import ax from "../axios";
import forge from "node-forge";
import { getDecryptedPrivateKey } from "../shared";
import { createAppointmentKeyStore } from "./appointment_key_store";

export const updateAppointment = (form) => {
  const content = encryptAppointment(form);
  return ax.put(`/appointments/${form.id}.json`, { params: { content } });
};

export const createNewAppointment = (values, currentEvent, user) => {
  return new Promise((resolve, reject) => {
    ax.get(`/calendars/${currentEvent.calendar_id}/permissioned_users`)
      .then((res) => {
        const contentJson = getJsonContent(values)
        const encryptedAppointmentObj =
          createAppointmentEncryptionObj(contentJson);

        const provisionedUsers = res.data.data;
        ax.post("/appointments.json", {
          params: {
            first_name:
              values.firstName[0] + "*".repeat(values.firstName.length),
            last_name:
              "*".repeat(values.lastName.length) +
              values.lastName[values.lastName.length - 1],
            iv: forge.util.encode64(encryptedAppointmentObj.iv),
            event_id: currentEvent.id,
            ansb_user_id: user.id,
          },
        })
          .then((appointmentCreationRes) => {
            const keyStoreCalls = [];
            provisionedUsers.forEach((user) => {
              const publicKey = forge.pki.publicKeyFromPem(
                user.attributes.public_key
              );
              const encryptedKey = publicKey.encrypt(
                encryptedAppointmentObj.symKey
              );
              const call = ax.post("/appointment_key_stores.json", {
                params: {
                  encrypted_sym_key: forge.util.encode64(encryptedKey),
                  user_id: user.id,
                  content: encryptedAppointmentObj.content,
                  appointment_id: appointmentCreationRes.data.data.id,
                },
              });
              keyStoreCalls.push(call);
            });

            // TODO: return promises array and display progress
            Promise.all(keyStoreCalls).then(resolve).catch(reject);
          })
          .catch(reject);
      })
      .catch(reject);
  });
};

/**
 * Takes the result from calendars#permissions and adds
 * keystores for every newly provisioned user and encrypted_appointment
 * @param {object} data
 * @returns Array of promises or empty array
 */
export const reEncryptAppointments = (data) => {
  const { provisioned, encrypted_appointments } = data;
  if (!provisioned.data?.length || !encrypted_appointments.length) {
    console.log(
      "no appointments in calendar or no new provisioned users",
      data
    );
    return [];
  }

  const myPrivateKey = getDecryptedPrivateKey();
  let apiCalls = [];

  encrypted_appointments.forEach((appointmentHash) => {
    // decrypt the key for this particular content
    const decryptedSymKey = myPrivateKey.decrypt(
      forge.util.decode64(
        appointmentHash.appointment_key_store.encrypted_sym_key
      )
    );

    provisioned.data.forEach((user) => {
      const forged = forge;
      const pki = forged.pki;
      // get the newly provisioned user's public key
      const userPublicKey = pki.publicKeyFromPem(user.attributes.public_key);
      // encrypt the symetric key with the user's public key
      const encryptedSymKey = forged.util.encode64(
        userPublicKey.encrypt(decryptedSymKey)
      );
      const keyStoreParams = {
        encrypted_sym_key: encryptedSymKey,
        user_id: user.id,
        content: appointmentHash.appointment_key_store.content,
        appointment_id: appointmentHash.id,
      };

      const prom = createAppointmentKeyStore(keyStoreParams);
      apiCalls.push(prom);
    });
  });

  return apiCalls;
};

export const getAppointment = (id) => {
  return ax.get(`/appointments/${id}`);
};
