import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
export const IconButton = ({ onClick, desc, title, icon }) => {
  const [t] = useTranslation();

  return (
    <div
      alt={t(desc)}
      title={t(desc)}
      onClick={onClick}
      className={`icon-button cursor-pointer ${icon}`}
    >
      <i title={t(title)} className={`fa fa-${icon}`}></i>
    </div>
  );
};

IconButton.propTypes = {
  desc: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.string,
};
