import React from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCalendars } from "../../api/calendar";
import { DashboardElement } from "./DashboardElement";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";

const CalendarList = () => {
  const [t] = useTranslation();
  const { calendars } = useSelector((state) => state.dashboard);

  useEffect(() => {
    getCalendars();
  }, []);

  return (
    <DashboardElement
      icon="calendar"
      title={t("calendar.available_calendars")}
      boxColor="gras-green"
      headerClass="new-appointments-overview"
    >
      <ul>
        {calendars &&
          calendars.map((calendar, index) => {
            return (
              <li key={"calendar" + index}>
                <Link to={`/calendars/${calendar.id}`}>
                  {calendar.attributes.title}
                </Link>
              </li>
            );
          })}
      </ul>
      {calendars && !calendars.length && <CommonSpinner />}
    </DashboardElement>
  );
};

CalendarList.defaultProps = {
  calendars: [],
};
CalendarList.propTypes = {
  calendars: PropTypes.array,
};

export default CalendarList;
