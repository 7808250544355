import React from "react";
import FooterNavigation from "./FooterNavigation";

const PageContainer = ({ children }) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="content-wrap">
          <div className="container">
            <div className="content-area">{children}</div>
          </div>
        </div>
      </div>
      <FooterNavigation />
    </React.Fragment>
  );
};

export default PageContainer;
