import React from "react";
import { UserRole } from "../../constants";
import { EventStatus } from "../../eventStatus";
import { useCurrentEvent } from "../../custom_hooks/events";
import { useUserRole } from "../../custom_hooks/user";
import { EventTimes } from "./EventTimes";
import { DecryptedAppointmentViewer } from "../appointment/DecryptedAppointmentViewer";
import AuthCode from "../common/AuthCode";

function EventDetails() {
  const currentEvent = useCurrentEvent();
  const role = useUserRole();

  if (!currentEvent) return null;

  return (
    <>
      <EventTimes start={currentEvent.start} end={currentEvent.end} />
      <div className="form-vertical seperated-details">
        <div className="lock-icon">
          <i className="fa fa-lock"></i>
        </div>
        <AuthCode>
          {/*
          // Makes editable when requested, currently buggy because IV is missing
          {currentEvent.status === EventStatus.REQUESTED &&
          role === UserRole.NAA ? (
            <DecryptedAppointment />
          ) : ( */}
          <DecryptedAppointmentViewer />
        </AuthCode>
      </div>
    </>
  );
}

export default EventDetails;
