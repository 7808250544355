import PropTypes from "prop-types";
import React from "react";
import { Col } from "react-bootstrap";
import MemberContent from "./MemberContent";
const chunk = require("lodash/chunk");
function MemberList({ members, onItemClick, mapRef }) {
  const renderMembers = () => {
    return chunk(members, 3).map((rowMembers, index) => {
      return (
        <div className="row" key={"row" + index}>
          {locationItems(rowMembers, index)}
        </div>
      );
    });
  };

  const locationItems = (rowMembers, chunkIndex) => {
    return rowMembers.map((item, index) => {
      const dataIndex = chunkIndex == 0 ? index : chunkIndex * 4 + index;
      return (
        <Col md={4}>
          <div
            className="location-data"
            key={index}
            onClick={(e) => {
              onItemClick(dataIndex, item, mapRef);
            }}
          >
            <MemberContent markerContent={item} />
          </div>
        </Col>
      );
    });
  };

  return (
    <div className="ansb-location-area">
      <Col md={12} className="ansb-location-area">
        <div className="ansb-location-results">{renderMembers()}</div>
      </Col>
    </div>
  );
}

MemberList.propTypes = {
  members: PropTypes.array,
  mapRef: PropTypes.any,
  onItemClick: PropTypes.func,
};

export default MemberList;
