import React from "react";
import { useTranslation } from "react-i18next";
import { UserRole } from "../../constants";
import { useUserRole } from "../../custom_hooks/user";
import { EventStatus, statusBackgroundColor } from "../../eventStatus";
import { StatusIndicator } from "../common/StatusIndicator";
import appConfig from "../../appConfig";
import { Col, Row } from "react-bootstrap";

export const CalendarLegend = () => {
  const [t] = useTranslation();
  const role = useUserRole();

  return (
    <Row className="legend-calendar">
      <Col md={12}>
        <h3>{t("calendar.legend")}:</h3>
        <div className="legend-calendar-body">
          <ul>
            <LegendEntry status={EventStatus.CREATED} />
            <LegendEntry status={EventStatus.ACCEPTED} />
            <LegendEntry status={EventStatus.CANCELED} />
            <LegendEntry status={EventStatus.BLOCKED} />
            {!appConfig.appointment_quick_accept && (
              <LegendEntry status={EventStatus.REQUESTED} />
            )}
            {role != UserRole.NAA && (
              <>
                <LegendEntry status={"accepted_not_in_kas"} />
                <LegendEntry status={"canceled_but_in_kas"} />
              </>
            )}
          </ul>
        </div>
      </Col>
    </Row>
  );
};

const LegendEntry = ({ status }) => {
  const [t] = useTranslation();

  return (
    <li className="event-status">
      <div className={`color-box ${statusBackgroundColor(status)}`}>
        <StatusIndicator status={status} />
      </div>
      <b>{t("event.status." + status)}</b>
    </li>
  );
};
