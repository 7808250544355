import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getUserData } from "../../api/user";
import { useCurrentUser } from "../../custom_hooks/user";
import { CommonForm } from "../common/CommonForm";
import { LocationFormFields } from "../forms/LocationFormFields";
import { createLocation, updateLocation } from "../../api/location";

function UpdateLocation() {
  const currentUser = useCurrentUser();
  const [userData, setUserData] = useState({});
  const [t] = useTranslation();

  const pullUserData = async () => {
    if (!currentUser?.email) return;
    const response = await getUserData(currentUser.email);
    setUserData(response.data.data);
  };

  useEffect(() => {
    pullUserData();
  }, [currentUser]);

  const { location, ansb_location_id } = userData?.attributes || {};

  const validationSchema = Yup.object().shape({
    clinic_name: Yup.string().required(
      t("validation.field_required", { field: t("location.clinic_name") })
    ),
    clinic_email: Yup.string().email(t("validation.email")).nullable(true),
    street: Yup.string().required(
      t("validation.field_required", { field: t("location.street") })
    ),
    postal_code: Yup.string()
      .typeError(
        t("validation.numbers_only", { field: t("location.postal_code") })
      )
      .required(
        t("validation.field_required", { field: t("location.postal_code") })
      ),
    city: Yup.string().required(
      t("validation.field_required", { field: t("location.city") })
    ),
    phone: Yup.number()
      .typeError(t("validation.numbers_only", { field: t("location.phone") }))
      .required(t("validation.field_required", { field: t("location.phone") })),
  });

  const onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      if (ansb_location_id) {
        updateLocation(values, ansb_location_id)
          .then((response) => {
            setUserData(response.data);
            resolve(response);
            pullUserData();
          })
          .catch(reject);
      } else {
        createLocation({ ...values, user_id: currentUser.id })
          .then((response) => {
            const newData = { ...userData };
            newData.attributes.location = response.data;
            setUserData(newData);
            resolve(response);
          })
          .catch(reject);
      }
    });
  };

  return (
    <CommonForm
      translationScope="update_location"
      apiEndpoint={onSubmit}
      initialValues={location}
      validationSchema={validationSchema}
      fieldsComponent={LocationFormFields}
      resultOnly={false}
    />
  );
}

export default UpdateLocation;
