import { LabeledRow } from "../common/LabeledRow";
import React, { useEffect, useState } from "react";
import {
  getActiveDates,
  validateNewEventTimes,
  dateToEventObject,
} from "../../dateFunctions";
import { getDayByIndex, getEventSeriesDates } from "../../shared";
import { useTranslation } from "react-i18next";
import { RuntimeDisplay } from "../events/RuntimeDisplay";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { getCalendars } from "../../api/calendar";
import { EventSeriesDetailRows } from "../event_series/EventSeriesDetailRows";
import { DatesLister } from "../event_series/DatesLister";

import appConfig from "../../appConfig";
import { useEvents } from "../../custom_hooks/events";

const dayArray = new Array(7).fill(1);
const monthArray = new Array(31).fill(1);
const hourArray = new Array(23).fill(1);

export const EventSeriesFormFields = ({ formik }) => {
  const { calendars } = useSelector((state) => state.dashboard);
  const { currentEventSeries } = useSelector((state) => state.appState);

  const { values, handleChange } = formik;
  const [selectedDays, setSelectedDays] = useState(values.selections || []);
  const [conflictedDates, setConflictedDates] = useState([]);
  const [validDates, setValidDates] = useState([]);
  const [t] = useTranslation();

  const allEvents = useEvents(values.calendar_id, 1);

  const today = new Date();

  useEffect(() => {
    if (!calendars?.length) getCalendars();
  }, []);

  const [startTimeDate, endTimeDate] = getEventSeriesDates(values);

  const generateDay = (item, index) => {
    const label = getDayByIndex(index);
    return generateEntry(label, index);
  };
  const generateNumericDay = (item, index) => {
    return generateEntry((index + 1).toString(), index);
  };

  const toggleDay = (index) => {
    const newSelected = [...selectedDays];
    newSelected.includes(index)
      ? newSelected.splice(newSelected.indexOf(index), 1)
      : newSelected.push(index);
    setSelectedDays(newSelected);
  };

  const generateEntry = (label, index) => {
    let extraClasses = selectedDays.includes(index) ? " selected" : "";
    return (
      <li
        className={`timeframe-day ${extraClasses}`}
        key={"day" + index}
        onClick={() => toggleDay(index)}
      >
        <p>{label}</p>
      </li>
    );
  };

  const filterSelectedDates = () => {
    const selectedDates = getActiveDates(values, selectedDays);
    let filteredEvents = allEvents;
    if (currentEventSeries?.id) {
      filteredEvents = allEvents.filter(
        (event) => event.series_id !== currentEventSeries.id
      );
    }

    const tentativeEvents = selectedDates.map((dt) =>
      dateToEventObject(dt, values.duration)
    );

    const [valids, conflix] = validateNewEventTimes(
      tentativeEvents,
      filteredEvents
    );

    setConflictedDates(conflix);
    setValidDates(valids);
  };

  useEffect(() => {
    const timestamps = validDates.map((event) =>
      Math.round(event.start.getTime() / 1000)
    );

    const newValues = {
      ...values,
      selections: selectedDays,
      timestamps,
    };
    formik.setValues(newValues);
  }, [validDates]);

  useEffect(() => {
    filterSelectedDates();
  }, [
    selectedDays,
    values.start_hour,
    values.start_minute,
    values.runtime_start,
    values.runtime_end,
    values.duration,
    values.repetition,
    values.calendar_id,
  ]);

  return (
    <div className="clearfix">
      <LabeledRow label={t("title")}>
        <input
          required
          className="form-control"
          name="title"
          onChange={handleChange}
          value={values.title}
        />
      </LabeledRow>
      <LabeledRow label={t("calendar.title")}>
        <select
          className="form-select"
          name="calendar_id"
          onChange={handleChange}
          value={values.calendar_id}
        >
          <option value="none">...</option>
          {calendars.map((calendar) => (
            <option key={calendar.id} value={calendar.id}>
              {calendar.attributes.title}
            </option>
          ))}
        </select>
      </LabeledRow>
      <LabeledRow label={t("event.series.times")}>
        <div className="rowflex flex-start">
          <label htmlFor="start_hour">{t("start_time")} </label>
          <select
            className="form-select"
            name="start_hour"
            onChange={handleChange}
            value={values.start_hour}
          >
            {hourArray.map((foo, index) => (
              <option key={index + 1} value={index}>
                {index}
              </option>
            ))}
          </select>
          <select
            className="form-select"
            name="start_minute"
            onChange={handleChange}
            value={values.start_minute}
          >
            {appConfig.startMinutes.map((mins, index) => (
              <option key={index + 1} value={mins}>
                {mins < 10 ? "0" + mins : mins}
              </option>
            ))}
          </select>
          <label htmlFor="duration">{t("duration")}</label>
          <select
            className="form-select"
            name="duration"
            onChange={handleChange}
            value={values.duration}
          >
            {appConfig.appointmentLengths.map((mins, index) => (
              <option key={index + 1} value={mins}>
                {mins}
              </option>
            ))}
          </select>
          {t("minutes")}
        </div>
        <RuntimeDisplay
          startTimeDate={startTimeDate}
          endTimeDate={endTimeDate}
          duration={values.duration}
        />
      </LabeledRow>

      <hr />

      <LabeledRow label={t("event.series.repetition")}>
        <label>
          <input
            type="radio"
            name="repetition"
            value="weekly"
            checked={values.repetition === "weekly"}
            onChange={handleChange}
          />
          {t("event.series.weekly")}
        </label>
        <label>
          <input
            type="radio"
            name="repetition"
            value="monthly"
            checked={values.repetition === "monthly"}
            onChange={handleChange}
          />
          {t("event.series.monthly")}
        </label>
      </LabeledRow>
      <LabeledRow label={t("event.series.running_days")}>
        <div className="timeframe-selector">
          <ul className="timeframe-list">
            {values.repetition === "weekly"
              ? dayArray.map(generateDay)
              : monthArray.map(generateNumericDay)}
          </ul>
        </div>
      </LabeledRow>
      <LabeledRow label={t("event.series.total_runtime")}>
        <ReactDatePicker
          name="runtime_start"
          onChange={(value) => {
            if (value <= values.runtime_end) {
              if (value >= today) {
                formik.setFieldValue("runtime_start", value);
              }
            }
          }}
          dateFormat="dd.MM.yyyy"
          selected={values.runtime_start}
        />
        &nbsp;{t("until")}&nbsp;
        <ReactDatePicker
          name="runtime_end"
          onChange={(value) => {
            if (value >= values.runtime_start) {
              formik.setFieldValue("runtime_end", value);
            }
          }}
          dateFormat="dd.MM.yyyy"
          selected={values.runtime_end}
        />
      </LabeledRow>

      <hr />

      <p>{t("event.series.creation_preview")}</p>
      <EventSeriesDetailRows
        values={values}
        startTimeDate={startTimeDate}
        endTimeDate={endTimeDate}
      />
      <LabeledRow label={t("event.series.days_display")}>
        <DatesLister events={validDates.slice(0, 20)} />
        {validDates.length > 20 &&
          `+${validDates.length - 20} ${t("entries_more")}`}
      </LabeledRow>
      {!!conflictedDates.length && (
        <LabeledRow label={t("event.conflicts")}>
          <div className="conflicting-dates">
            <DatesLister events={conflictedDates} />
            <p className="small">{t("event.conflict_desc")}</p>
          </div>
        </LabeledRow>
      )}
    </div>
  );
};
