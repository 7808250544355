import React from "react";
import { useCurrentUser } from "../custom_hooks/user";
import Dashboard from "./Dashboard";
import { FileTransfer } from "./files/FileTransfer";
import LoginForm from "./login/LoginForm";

export const RootView = () => {
  const currentUser = useCurrentUser();
  const { enable_file_transfer, enable_appointment_scheduler } =
    window.ITREF.config;

  if (!currentUser) return <LoginForm />;

  if (enable_appointment_scheduler || currentUser.user_role !== "naa")
    return <Dashboard />;

  if (enable_file_transfer) return <FileTransfer id={currentUser.id} />;

  return (
    <div className="alert alert-warning">
      Appointment scheduler and file transfer both disabled in tenant
      configuration!
    </div>
  );
};
