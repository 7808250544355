import React from "react";
import { useCurrentUser } from "../custom_hooks/user";
import { useTranslation } from "react-i18next";
import appConfig from "../appConfig";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function PublicQuickLinks() {
  const currentUser = useCurrentUser();
  const [t] = useTranslation();

  return (
    <>
      {!currentUser && (
        <Nav>
          <LinkContainer to="/">
            <Nav.Link>
              <span>
                <i type="fas" className="fa fa-sign-in-alt"></i>
              </span>
              {t("login.login")}
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to="/sign_up">
            <Nav.Link>
              <span>
                <i type="fas" className="fa fa-user-plus"></i>
              </span>
              {t("sign_up.header")}
            </Nav.Link>
          </LinkContainer>
          {appConfig.show_member_map && (
            <LinkContainer to="/member_map">
              <Nav.Link>
                <span>
                  <i type="fas" className="fas fa-map-marker-alt"></i>
                </span>
                {t("quick_links.member_map")}
              </Nav.Link>
            </LinkContainer>
          )}
        </Nav>
      )}
    </>
  );
}

export default PublicQuickLinks;
