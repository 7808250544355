import * as React from "react";
import { useEffect, useState } from "react";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { sendUploadInfo } from "../../api/files";
import { UserRole } from "../../constants";
import { useCurrentUser } from "../../custom_hooks/user";
import { AnsbUser, SelectOption } from "../../types";
import { ActionButton } from "../appointment/ActionButton";
import Select from "../forms/Select";

interface PropDefs {
  ownerDetails: AnsbUser;
  items?: SelectOption[];
  users?: AnsbUser[];
  title: string;
  description: string;
  placeholder?: string;
}

export const Informant = ({
  items,
  users,
  ownerDetails,
  title,
  placeholder,
  description,
}: PropDefs) => {
  const currentUser: AnsbUser | null = useCurrentUser();
  const [t] = useTranslation();
  const [inform, setInform] = useState(false);
  const [informResult, setInformResult] = useState(null);
  const [informing, setInforming] = useState(false);
  const [informantId, setInformantId] = useState<number>(0);

  useEffect(() => {
    if (currentUser && currentUser.user_role !== UserRole.NAA) setInform(true);
  }, [currentUser]);

  const triggerInfoMail = async () => {
    setInforming(true);
    setInformResult(null);

    let infoParams: object;
    if (items) {
      infoParams = { field_id: informantId }
    } else {
      infoParams = { user_id: informantId }
    }

    let result = await sendUploadInfo(ownerDetails.id, infoParams);
    setInforming(false);
    setInformResult(result);
  };

  useEffect(() => {
    setInformResult(null);
    setInform(!!informantId);
  }, [informantId]);

  useEffect(() => {
    if (informResult) setInform(false)
  }, [informResult]);

  useEffect(() => {
    if (items?.length === 1) {
      setInformantId(items[0].id);
    } else if (users?.length === 1) {
      setInformantId(users[0].id);
    }
  }, [items, users]);

  let informants: SelectOption[] = [];
  if(users && users.length > 0) {
    informants = users.map((user: AnsbUser) => ({
      id: user.id,
      title: `${user.attributes.first_name} ${user.attributes.last_name}`,
    }));
  }

  const entryPlug: SelectOption[] = items || informants;

  if (!currentUser) return null;

  return (
    <div>
      <h4>{t(title)}</h4>
      <p className="small">{t(description)}</p>

      {entryPlug.length > 1 && (
        <>
          <Select
            placeholder={placeholder}
            items={entryPlug}
            onChange={setInformantId}
          />
          <br />
        </>
      )}

      {informing ? (
        <CommonSpinner />
      ) : (
        <ActionButton
          icon="paper-plane"
          variant="outline-primary"
          onClick={triggerInfoMail}
          title="files.inform_user"
          disabled={!inform || informantId === 0}
        />
      )}

      {informResult?.error ? (
        <Alert variant="danger" transition={false}>
          {informResult?.error?.message}
        </Alert>
      ) : null}

      {informResult && (
        <Alert variant="success" transition={false}>
          {t("files.info_mail_sent")}
        </Alert>
      )}
    </div>
  );
};

export default Informant;
