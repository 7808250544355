import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { remoteEventStatusChange } from "../actions/dashboard";
import { consumer } from "../cable";

export const CableHandler = () => {
  const { currentUser } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser) {
      // TODO: delete the line below when production and staging are properly configured to support the websocket
      return;
      consumer.subscriptions.create(
        { channel: "EventChannel" },
        {
          received(data) {
            dispatch(remoteEventStatusChange(data));
          },
        }
      );
    } else {
      // unsubscribe
    }
  }, [currentUser]);

  return null;
};
