/**
 * Config options can be overwritten by setting window.ITREF.config object
 * window.ITREF.config = { valueToOverwrite: 1 }
 *
 * Parameters for event series
 *  - appointmentIntervals | creates selectable lengths for an appointment in EventSeriesFormFields.jsx
 *  - maxAppointmentLength | maximum selectable length of an appointment
 *  - startMinuteIntervals | how detailed each selectable fraction of an hour should be
 *  - defaultAppointmentDuration | length of event in minutes
 */
const defaultConfig = {
  appointmentIntervals: 5,
  maxAppointmentLength: 180,
  startMinuteIntervals: 5,
  defaultAppointmentDuration: 30,
  debug: window.location.host.indexOf("localhost") > -1,
  show_member_map: true,
};

/**
 * @returns configuration object with custom variables from window.ITREF.config merged in, if set
 */
export const getConfig = function () {
  let appConfig = defaultConfig;
  if (window.ITREF?.config) {
    appConfig = { ...defaultConfig, ...window.ITREF.config };
  }

  appConfig.startMinutes = new Array(
    Math.round(60 / appConfig.startMinuteIntervals)
  )
    .fill(1)
    .map((value, index) => index * appConfig.startMinuteIntervals);
  appConfig.appointmentLengths = new Array(
    Math.round(appConfig.maxAppointmentLength / appConfig.appointmentIntervals)
  )
    .fill(1)
    .map((value, index) => (index + 1) * appConfig.appointmentIntervals);

  return appConfig;
};

/**
 * returns a config object
 */
export default getConfig();
