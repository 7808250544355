import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import deLocale from "@fullcalendar/core/locales/de";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { updateEvent } from "../../api/event";
import { isPast } from "../../dateFunctions";
import {
  DEFAULT_EVENT_LENGTH_SECONDS,
  ModalID,
  UserRole,
} from "../../constants";
import { EventStatus, statusBackgroundColor } from "../../eventStatus";
import { useEvents } from "../../custom_hooks/events";
import { openEventModal } from "../../shared";
import { StatusIndicator } from "../common/StatusIndicator";
import { CalendarLegend } from "./CalendarLegend";
import { useCurrentUser, useUserRole } from "../../custom_hooks/user";
import { useCalendar } from "../../custom_hooks/calendars";
import { useTranslation } from "react-i18next";
import { ContentContainer } from "../common/ContentContainer";

function CalendarShow() {
  const { id } = useParams();
  const [lastUpdate, setLastUpdate] = useState(0);
  // TODO: Refactor because useEvents and useCalendar each call getCalendar which is costly
  const events = useEvents(id, lastUpdate);
  const calendar = useCalendar(id, lastUpdate);
  const role = useUserRole();
  const currentUser = useCurrentUser();
  const [t] = useTranslation();

  useEffect(() => {
    deLocale.buttonText.list = t("calendar.year_overview");
  }, []);

  const { showModal, remoteEventUpdates } = useSelector(
    (state) => state.appState
  );

  useEffect(() => {
    setLastUpdate(Date.now());
  }, [remoteEventUpdates]);

  useEffect(() => {
    if (!showModal.includes(ModalID.EVENT)) {
      setLastUpdate(Date.now());
    }
  }, [showModal]);

  const handleEventClick = ({ event }) => {
    openCurrentEvent({
      start: event.start,
      end: event.end,
      title: event.title,
      id: event.id,
      status: event.extendedProps.status,
      event_series_meta_id: event.extendedProps.event_series_meta_id,
      entered: event.extendedProps.entered,
    });
  };

  const handleEventDropResize = (event) => {
    const { title, start, end, id } = event.event;
    const updateValues = { title, start, end };
    updateEvent(id, updateValues);
  };

  const handleSelectEvent = (event) => {
    if (role !== UserRole.NAA) {
      event.start.setMinutes(0);
      if (event.start.getHours() === 0) {
        event.start.setHours(new Date().getHours());
        event.end = new Date(event.start);
        event.end.setSeconds(DEFAULT_EVENT_LENGTH_SECONDS);
      }
      event.status = EventStatus.DRAFT;
      openCurrentEvent(event);
    }
  };

  const applyBackgroundColor = (eventInfo) => {
    const { status, entered } = eventInfo?.event?.extendedProps;
    const extraClass =
      (entered && status === EventStatus.CANCELED) ||
      (!entered && status === EventStatus.ACCEPTED)
        ? " half-opacity"
        : "";

    return (
      statusBackgroundColor(status) + (role !== UserRole.NAA ? extraClass : "")
    );
  };

  const openCurrentEvent = (event) => {
    const wantedEvent = { ...event, calendar_id: id };
    openEventModal(wantedEvent);
  };

  const generateEventContent = (eventInfo) => {
    const { status } = eventInfo?.event?.extendedProps;

    return (
      <div className="rowflex event-content">
        <StatusIndicator status={status} />
        <b>{eventInfo.timeText}</b>&nbsp;
        <i>{eventInfo.event.title}</i>
      </div>
    );
  };

  const limitedEvents =
    role === UserRole.NAA
      ? events.filter(
          ({ appointment_user_id }) =>
            !appointment_user_id || appointment_user_id == currentUser.id
        )
      : events;

  const handleSelectAllow = (event) => {
    const gone = !isPast(event.end);
    return gone;
  };

  return (
    <ContentContainer title={calendar?.attributes?.title}>
      <p className="small">{calendar?.attributes?.description}</p>
      <FullCalendar
        events={limitedEvents}
        plugins={[timeGridPlugin, dayGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        eventClick={handleEventClick}
        eventDrop={handleEventDropResize}
        eventResize={handleEventDropResize}
        select={handleSelectEvent}
        locale={deLocale}
        selectable={true}
        selectAllow={handleSelectAllow}
        editable={true}
        nowIndicator={true}
        eventContent={generateEventContent}
        eventClassNames={applyBackgroundColor}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listYear",
        }}
      />
      <CalendarLegend />
    </ContentContainer>
  );
}

export default CalendarShow;
