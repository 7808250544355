import React from "react";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { blockEvent, unblockEvent } from "../../api/event";
import {
  DEFAULT_EVENT_BLOCKED_SECONDS,
  UNBLOCK_WARNING_BEFORE_SECONDS,
} from "../../constants";
import { ActionButton } from "../appointment/ActionButton";

export const EventBlockStatus = ({ eventID, started }) => {
  const [warnTime, setWarnTime] = useState(UNBLOCK_WARNING_BEFORE_SECONDS + 1);
  const secondsRemaining = useRef(UNBLOCK_WARNING_BEFORE_SECONDS + 1);
  const timer = useRef(0);
  const warnTimer = useRef(0);
  const [t] = useTranslation();

  const triggerTimeouts = () => {
    cancelTimeouts();
    setWarnTime(UNBLOCK_WARNING_BEFORE_SECONDS + 1);

    timer.current = setTimeout(() => {
      unblockEvent(eventID)
        .then((e) => setWarnTime(0))
        .catch((e) => setWarnTime(60));

      cancelTimeouts();
    }, DEFAULT_EVENT_BLOCKED_SECONDS * 1000);

    // warn a minute before
    warnTimer.current = setTimeout(() => {
      secondsRemaining.current = UNBLOCK_WARNING_BEFORE_SECONDS;
      setWarnTime(UNBLOCK_WARNING_BEFORE_SECONDS);

      warnTimer.current = setInterval(countDownWarnTime, 1000);
    }, (DEFAULT_EVENT_BLOCKED_SECONDS - UNBLOCK_WARNING_BEFORE_SECONDS) * 1000);
  };

  const countDownWarnTime = () => {
    secondsRemaining.current = secondsRemaining.current - 1;
    setWarnTime(secondsRemaining.current);
  };

  const cancelTimeouts = () => {
    clearInterval(warnTimer.current);
    clearTimeout(warnTimer.current);
    clearTimeout(timer.current);
  };

  useEffect(() => {
    started ? triggerTimeouts() : cancelTimeouts();
    return cancelTimeouts;
  }, [started]);

  const reblockEvent = () => {
    blockEvent(eventID)
      .then(triggerTimeouts)
      .catch((e) => {
        console.log("probably already chosen by someone else, reload calendar");
        // TODO close event, reload calendar
      });
  };

  if (!started || warnTime > 60) return null;

  return (
    <div className="alert alert-danger">
      {warnTime > 0 ? (
        <>
          <p>
            {t("event.will_be_unblocked_in_seconds", { seconds: warnTime })}
          </p>
          <ActionButton title="event.extend_block" onClick={triggerTimeouts} />
        </>
      ) : (
        <>
          <p>{t("event.was_unblocked")}</p>
          <ActionButton title="event.block_again" onClick={reblockEvent} />
        </>
      )}
    </div>
  );
};
