import "leaflet/dist/leaflet.css";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useUsers } from "../custom_hooks/user";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";
import MemberList from "./member_map/MemberList";
import MarkerLayer from "./member_map/MarkerLayer";
import { TileLayer, MapContainer } from "react-leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import { latlngforMap } from "../shared";
import "leaflet-geosearch/dist/geosearch.css";
import MapsSearch from "./member_map/MapsSearch";
import { useTranslation } from "react-i18next";
import { MemberMapDefaults } from "../constants";
import { getLocations } from "../api/location";
import FooterNavigation from "../components/FooterNavigation";
import PageContainer from "./PageContainer";
import { Container } from "react-bootstrap";

// needed for pulling the default OpenStreetMaps icons
let DefaultIcon = L.icon({
  ...L.Icon.Default.prototype.options,
  iconUrl: icon,
  iconRetinaUrl: iconRetina,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;
// end

const memberMapContainer = document.getElementById("member_map");

function MemberMap() {
  const [t] = useTranslation();
  const [mapRef, setRefMap] = useState(null);
  const [users, setUsers] = useState([]);
  const [center] = useState(MemberMapDefaults.MUNICH_CENTER_LAT_LNG);
  const [zoom] = useState(MemberMapDefaults.DEFAULT_ZOOM_LEVEL);
  const [selected, setSelected] = useState();

  const handleItemClick = (index, item) => {
    mapRef.flyTo(latlngforMap(item.attributes.geo_code));
    setSelected(index);
  };

  const handleSearchResultClick = (element) => {
    mapRef.flyTo({ lat: element.y, lng: element.x });
  };

  useEffect(() => {
    getLocations()
      .then((res) => {
        const filtered = res.data.filter((u) => u.attributes.geo_code);
        setUsers(filtered);
      })
      .catch((e) => setUsers([]));
  }, []);

  if (!users) return <CommonSpinner />;

  return (
    <Container>
      <h1>{t("member_map.title")}</h1>
      {users && (
        <div className="ansb-location-app">
          <div className="ansb-location-area">
            <MapsSearch handleSearchResultClick={handleSearchResultClick} />
          </div>
          <div>
            <MapContainer
              center={center}
              zoom={zoom}
              style={{ height: "600px", width: "100%" }}
              scrollWheelZoom={false}
              whenCreated={setRefMap}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MarkerLayer selectedIndex={selected} data={users} />
            </MapContainer>
          </div>
          <MemberList
            members={users}
            onItemClick={handleItemClick}
            handleSearchResultClick={handleSearchResultClick}
          />
        </div>
      )}
    </Container>
  );
}

export default MemberMap;
