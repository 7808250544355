import { Formik, Field, Form } from "formik";
import FormikDatePicker from "../FormikDatePicker";
import { LabeledRow } from "../common/LabeledRow";
import { DeleteButton } from "../common/DeleteButton";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideEventModal, updateCurrentEvent } from "../../actions/app";
import { createEvent, destroyEvent, updateEvent } from "../../api/event";
import { NotificationLayer } from "../common/NotificationLayer";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { FormikErrorList } from "../common/FormikErrorList";
import { before, sameDay } from "../../dateFunctions";
import { checkMaxDurationInHour } from "../../shared";
import { EventSeriesEditor } from "../event_series/EventSeriesEditor";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { clearCurrentEventSeries } from "../../actions/app";
import { Col, Row } from "react-bootstrap";

export const EventEditor = ({ calendar_id }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { currentEvent } = useSelector((store) => store.appState);
  const { id } = currentEvent;
  const [apiResponse, setApiResponse] = useState(null);

  const [saving, setSaving] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(
      t("validation.field_required", { field: t("title") })
    ),
    start: Yup.date().required(
      t("validation.field_required", { field: t("start_time") })
    ),
    end: Yup.date()
      .required(t("validation.field_required", { field: t("end_time") }))
      .when("start", (start, schema) => {
        return schema.test({
          test: (end) => before(start, end, true),
          message: t("event.validation.start_before_end"),
        });
      })
      .when("start", (start, schema) => {
        return schema.test({
          test: (end) => sameDay(start, end),
          message: t("event.validation.same_day"),
        });
      }),
    /* TODO: uncomment and adjust this validation if max duration can be configured per calendar */
    /*.when("start", (start, schema) => {
        return schema.test({
          test: (end) => checkMaxDurationInHour(start, end, 6),
          message: t("event.validation.duration", { hours: 6 }),
        });
      }),*/
  });

  const updateEventData = (values) => {
    updateEvent(id, values).then(handleResponse);
  };

  const handleResponse = (res) => {
    setSaving(false);
    if (res.status === 200) {
      dispatch(hideEventModal());
    } else {
      setApiResponse(res);
    }
  };

  const showEventSeriesEditor = !id && (
    <>
      <br />
      <h2>{t("event.series.create")}</h2>
      <EventSeriesEditor />
    </>
  );

  const handleDestroyEvent = () => {
    setSaving(true);
    destroyEvent(id).then(handleResponse);
  };

  const createEventHandler = (values) => {
    createEvent(values, calendar_id)
      .then(handleResponse)
      .catch((res) => {
        setSaving(false);
        setApiResponse(res);
      });
  };

  const onSubmit = (values) => {
    setSaving(true);
    if (!id) {
      createEventHandler(values);
    } else {
      updateEventData(values);
    }
  };

  const layoutDecider = () => {
    // new Event
    if (!id) {
      purgeOldEventSeries();
      return tabLayout();
    } //old Event
    else return singleEvent();
  };

  const purgeOldEventSeries = () => {
    // purge globalState since a Series could have been opened before
    dispatch(clearCurrentEventSeries());
  };

  const tabLayout = () => {
    return (
      <Tabs
        defaultActiveKey="singular"
        transition={false}
        id="noanim-tab-example"
        className="mb-3"
      >
        <Tab eventKey="singular" title={t("event.tabs.singular")}>
          {singleEvent()}
        </Tab>
        <Tab eventKey="series" title={t("event.tabs.series")}>
          {showEventSeriesEditor}
        </Tab>
      </Tabs>
    );
  };

  const singleEvent = () => {
    return (
      <div>
        <Formik
          initialValues={currentEvent}
          onSubmit={onSubmit}
          enableReinitialize={true}
          validateOnBlur={true}
          validationSchema={validationSchema}
        >
          {({ values, errors }) => (
            <Form>
              <div className="form-inputs">
                <LabeledRow label={t("title")}>
                  <Field
                    className="form-control string required"
                    id="title"
                    name="title"
                    type="text"
                  ></Field>
                </LabeledRow>
                <LabeledRow label={t("event.timeframe")}>
                  <FormikDatePicker
                    name="start"
                    dateFormat={"dd.MM.yyyy H:mm"}
                    showTimeSelect={true}
                  />
                  {t("until")}
                  <FormikDatePicker
                    name="end"
                    showTimeSelect={true}
                    dateFormat={"dd.MM.yyyy H:mm"}
                  />
                </LabeledRow>
                <FormikErrorList errors={errors} />
                <Row>
                  <Col md={12} className="rowflex space-between">
                    {id ? (
                      <>
                        <input
                          type="submit"
                          disabled={saving || errors.length}
                          value={t("update")}
                          className="btn btn button-lg ghost-button grassgreen-button-invers"
                        />
                        <DeleteButton
                          disabled={saving}
                          value={t("delete")}
                          onClick={handleDestroyEvent}
                        />
                      </>
                    ) : (
                      <input
                        value={t("create")}
                        disabled={saving || errors.length > 0}
                        type="submit"
                        className="btn btn button-lg ghost-button grassgreen-button-invers"
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Formik>
        {partOfEventSeries()}
      </div>
    );
  };

  const partOfEventSeries = () => {
    return (
      currentEvent.event_series_meta_id && (
        <div>
          <hr />
          <p>{t("event.part_of_series")}</p>
          <Link
            to={`/event_series/${currentEvent.event_series_meta_id}`}
            onClick={() => dispatch(hideEventModal())}
          >
            {t("event.show_series")}
          </Link>
        </div>
      )
    );
  };

  return (
    <div>
      <NotificationLayer
        apiResponse={apiResponse}
        customTrigger={apiResponse}
      />
      {layoutDecider()}
    </div>
  );
};
