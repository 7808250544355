import PropTypes from "prop-types";
import { EventStatus } from "../../eventStatus";
import { AppointmentQuickActions } from "../appointment/AppointmentQuickActions";
import { CommonEventView } from "./CommonEventView";

export const EventList = ({ events, children }) => {
  const sortedEvents = events.sort(({ status }) => {
    return status === EventStatus.REQUESTED ? -1 : 1;
  });

  return (
    <div className="calendar-entry-list">
      {sortedEvents.map((event, index) => (
        <CommonEventView event={event} {...event} key={index}>
          <AppointmentQuickActions appointment={event} />
        </CommonEventView>
      ))}
      {children}
    </div>
  );
};

EventList.defaultProps = {
  events: [],
};

EventList.propTypes = {
  events: PropTypes.array.isRequired,
};
