import React from "react";
import PropTypes from "prop-types";

/**
 * @constructor
 * @param {string} name - name given to input[type=checkbox]
 * @param {string} label - Text to display. Apllied to <Label> element
 * @param {bool} checked - Is the checkbox checked or not
 * @param {func} onChange - function to run when the checkbox is clicked
 * @param {bool} disabled - is the Checkbox disabled?
 */

export default function Checkbox({ disabled, name, label, checked, onChange }) {
  return (
    <span>
      <input
        className="checkbox-input"
        type="checkbox"
        name={name}
        id={`${name}-id`}
        disabled={disabled}
        onChange={disabled ? null : onChange}
        defaultChecked={checked}
        area-labelledby="checkbox-label"
      />
      {label && (
        <label className="checkbox-label" htmlFor={`${name}-id`}>
          {label}
        </label>
      )}
    </span>
  );
}

Checkbox.defaultProps = {
  disabled: false,
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
