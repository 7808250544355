import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LabeledRow } from "./../common/LabeledRow";

export const UserFormFields = ({ formik, withEmail }) => {
  const [t] = useTranslation();

  return (
    <>
      <LabeledRow label={t("user.title")}>
        <input
          name="title"
          className="form-control"
          onChange={formik.handleChange}
          defaultValue={formik.values.title}
        />
      </LabeledRow>
      <LabeledRow label={t("user.first_name")} required={true}>
        <input
          name="first_name"
          className="form-control"
          onChange={formik.handleChange}
          defaultValue={formik.values.first_name}
        />
      </LabeledRow>
      <LabeledRow label={t("user.last_name")} required={true}>
        <input
          name="last_name"
          className="form-control"
          onChange={formik.handleChange}
          defaultValue={formik.values.last_name}
          required={true}
        />
      </LabeledRow>
      {withEmail && (
        <LabeledRow label={t("email")} required={true}>
          <input
            name="email"
            type="email"
            className="form-control"
            onChange={formik.handleChange}
            defaultValue={formik.values.email}
          />
        </LabeledRow>
      )}
    </>
  );
};

UserFormFields.defaultProps = {
  withEmail: true,
};

UserFormFields.propTypes = {
  formik: PropTypes.object,
  withEmail: PropTypes.bool,
};
