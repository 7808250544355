import React from "react";
import { UserRole, ModalID } from "../../constants";
import { EventStatus, titleByStatus } from "../../eventStatus";
import { useCurrentEvent } from "../../custom_hooks/events";
import { EventActions } from "../events/EventActions";
import EventDetails from "../events/EventDetails";
import { CommonModal } from "./CommonModal";
import { useTranslation } from "react-i18next";
import { useUserRole } from "../../custom_hooks/user";
import DecryptedAppointment from "../appointment/DecryptedAppointment";
import { EventTimes } from "../events/EventTimes";
import { EventEditor } from "../events/EventEditor";
import appConfig from "../../appConfig";
import { EnteredFlagSwitcher } from "../events/EnteredFlagSwitcher";
import { LabeledRow } from "./LabeledRow";
import AuthCode from "./AuthCode";

export const EventModal = () => {
  const currentEvent = useCurrentEvent();
  const role = useUserRole();
  const [t] = useTranslation();

  if (!currentEvent || !currentEvent.hasOwnProperty("start")) {
    return <div></div>;
  }

  const { status, start, end, calendar_id, title } = currentEvent;

  let modalTitle = titleByStatus[status] || t("event.create");
  modalTitle = modalTitle.replace("${title}", title);

  let contents;
  switch (status) {
    case EventStatus.CREATED:
      if (role === UserRole.NAA) {
        contents = <DecryptedAppointment />;
      } else {
        contents = <EventEditor />;
      }
      break;
    case EventStatus.BLOCKED:
      contents = (
        <>
          <EventTimes start={start} end={end} />
          <p>{t("event.blocked_by_user")}</p>
          {role === UserRole.HTK && <EventActions />}
        </>
      );
      break;
    case EventStatus.DRAFT:
      contents = <EventEditor calendar_id={calendar_id} />;
      break;
    default:
      const switchLabel =
        status == EventStatus.ACCEPTED
          ? "event.entered_in_kas"
          : "event.removed_from_kas";
      contents = (
        <>
          <EventDetails />
          {appConfig.appointment_quick_accept && role !== "naa" && (
            <LabeledRow label={t(switchLabel)}>
              <EnteredFlagSwitcher status={status} />
            </LabeledRow>
          )}
          <hr />
          <EventActions />
        </>
      );
      break;
  }

  return (
    <CommonModal title={modalTitle} modalId={ModalID.EVENT}>
      <AuthCode>{contents}</AuthCode>
    </CommonModal>
  );
};
