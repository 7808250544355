import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { CommonForm } from "../common/CommonForm";
import { invite } from "../../api/user";
import { TtkInviteFormFields } from "../forms/TtkInviteFormFields";

export const InviteForm = () => {
  const [t] = useTranslation();

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(
      t("validation.field_required", { field: t("user.first_name") })
    ),
    last_name: Yup.string().required(
      t("validation.field_required", { field: t("user.last_name") })
    ),
    email: Yup.string()
      .email(t("validation.email"))
      .required(t("validation.email")),
  });

  return (
    <CommonForm
      fieldsComponent={TtkInviteFormFields}
      translationScope="invite"
      apiEndpoint={invite}
      validationSchema={validationSchema}
    />
  );
};
