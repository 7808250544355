import React from "react";
import { useTranslation } from "react-i18next";
import { LabeledRow } from "../common/LabeledRow";
import { UserFormFields } from "./UserFormFields";
import { LocationFormFields } from "./LocationFormFields";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const SignupFormFields = ({ formik }) => {
  const [t] = useTranslation();

  return (
    <>
      <h4>{t("sign_up.your_details")}</h4>

      <UserFormFields formik={formik} />

      <h4>{t("your_clinic_details")}</h4>

      <LocationFormFields formik={formik} />

      <LabeledRow label={t("message")} required={false}>
        <textarea
          name="message"
          className="form-control"
          onChange={formik.handleChange}
        >
          {formik.values.message}
        </textarea>
      </LabeledRow>
      <Row>
        <Col sm={12}>
          {t("sign_up.terms_of_service_accept")}
          <a href="https://www.lmu-klinikum.de/data_safety" target="_blank">
            {t("sign_up.privacy_policy")}
          </a>
          {t("sign_up.terms_of_service_accept_2")}
        </Col>
      </Row>
    </>
  );
};
