import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ACCESS_RIGHTS } from "../../constants";
import { useRights } from "../../custom_hooks/user";
import { hasRight } from "../../shared";
import AllEvents from "./AllEvents";
import CalendarList from "./CalendarList";
import CanceledEvents from "./CanceledEvents";
import NewAppointments from "./NewAppointments";
import NewMembers from "./NewMembers";
import PasswordReset from "./PasswordReset";
import SettingsElement from "./SettingsElement";
import TransferElement from "./TransferElement";

const TtkDashboardIndex = () => {
  const [t] = useTranslation();
  const rights = useRights();

  let views = [];
  const cals =
    hasRight(ACCESS_RIGHTS.calendar) &&
    window.ITREF.config.enable_appointment_scheduler;

  if (
    hasRight(ACCESS_RIGHTS.file_transfer) &&
    window.ITREF.config.enable_file_transfer
  ) {
    views.push(<TransferElement key="transfers" />);
  }
  if (cals) {
    views.push(<NewAppointments key="newAppointments" />);
    views.push(<CanceledEvents key="canceled" />);
  }

  if (hasRight(ACCESS_RIGHTS.confirm_users)) {
    views.push(<NewMembers key="nm" />);
    views.push(<PasswordReset key="passwordReset" />);
  }

  if (cals) {
    views.push(<CalendarList key="clist" />);
    views.push(<AllEvents key="clist" />);
  }

  return (
    <Row className="dashboard">
      {!rights.length ? (
        <Col md={12}>{!rights.length && <p>{t("dashboard.no_rights")}</p>}</Col>
      ) : (
        <>
          {views}
          <SettingsElement />
        </>
      )}
    </Row>
  );
};
export default TtkDashboardIndex;
