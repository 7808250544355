import React from "react";
import PropTypes from "prop-types";
import { displayDate } from "../../dateFunctions";

export const DatesLister = ({ events }) => {
  const pieces = events.map((event, index) => (
    <React.Fragment key={index}>
      <span className="event-preview-item">
        {displayDate(event.start, { day: "numeric", month: "numeric" })}
      </span>{" "}
    </React.Fragment>
  ));

  return pieces;
};

DatesLister.propTypes = {
  className: PropTypes.any,
  events: PropTypes.array.isRequired,
};
