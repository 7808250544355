import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentAppointment } from "../../custom_hooks/events";
import { LabeledRow } from "../common/LabeledRow";
import { Paragraph } from "./Paragraph";

export const DecryptedAppointmentViewer = () => {
  const [t] = useTranslation();
  const appointment = useCurrentAppointment();

  if (!appointment) return null;

  const {
    salutation,
    firstName,
    lastName,
    birthDay,
    reachability,
    insuranceType,
    question,
    suspicion,
    lmuPatient,
  } = appointment;

  return (
    <>
      <LabeledRow label="Patient">
        <p>
          <strong>
            {`${t(salutation)} ${firstName} ${lastName}`}
            <br />
            {t("birthday")}&nbsp;
            {new Date(birthDay).toLocaleDateString(navigator.language)}
          </strong>
        </p>
        <Paragraph appointment={appointment} label="phone" />
        <Paragraph appointment={appointment} label="email" />
      </LabeledRow>
      <LabeledRow label={t("appointments.preferred_inform_method")}>
        <p>{t(reachability)}</p>
      </LabeledRow>
      <LabeledRow label={t("insurance_type.label")}>
        {t("insurance_type." + (insuranceType || "legal"))}
      </LabeledRow>
      <LabeledRow label={t("already_lmu_patient")}>
        {lmuPatient ? "✓" : "⨯"}
      </LabeledRow>
      <LabeledRow label={t("diagnosis")}>{suspicion}</LabeledRow>
      <LabeledRow label={t("appointments.question")}>{question}</LabeledRow>
    </>
  );
};
