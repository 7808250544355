import * as React from 'react'
import { Suspense } from "react";
import { Provider } from "react-redux";
import { store } from "../store/store";
import { BrowserRouter as Router } from "react-router-dom";
import { Routing } from "./Routing";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";
import { EventModal } from "./common/EventModal";
import { CableHandler } from "./CableHandler";
import "../i18n";
import ConfirmationModal from "./common/ConfirmationModal";
import CookieConsent from "./CookieConsent";
import NavigationBar from "./NavigationBar";
import UserQuickLinks from "./login/UserQuickLinks";
import PublicQuickLinks from "./PublicQuickLinks";
import { Nav } from 'react-bootstrap';

function ReferralPortal() {

  return (
    <Provider store={store}>
      <Router>
        <Suspense
          fallback={
            <div className="container">
              <CommonSpinner />
            </div>
          }
        >
          <CableHandler />
          <NavigationBar>
            <Nav>
              <UserQuickLinks />
              <PublicQuickLinks />
            </Nav>
          </NavigationBar>
          <Routing />
          <ConfirmationModal />
          <EventModal />
          <CookieConsent />
        </Suspense>
      </Router>
    </Provider>
  );
}

export default ReferralPortal;
