import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { updateShowModal } from "../../actions/app";
import { ActionButton } from "../appointment/ActionButton";
import { ReduxState } from "../../types";

interface PropDefs {
  title: string;
  modalId: string;
  size?: "sm" | "lg" | "xl";
  children: React.ReactNode;
}

/**
 * Adds a modal that displays by invoking
 * reduxStore.updateShowModal(modalId)
 *
 * @param {title} string | title of the modal
 * @param {modalId} string | id of the modal
 * @returns JSXElement
 */
export const CommonModal = ({ children, title, modalId, size = "sm" }:PropDefs) => {
  const { showModal } = useSelector((state: ReduxState) => state.appState);
  const dispatch = useDispatch();
  const visible = showModal.includes(modalId);

  const hideModal = () => dispatch(updateShowModal(modalId));

  return (
    <Modal
      centered
      size={size}
      animation={false}
      show={visible}
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <div className="modal-title h4">{title}</div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <ActionButton variant="outline-primary" onClick={hideModal} title={"close"} />
      </Modal.Footer>
    </Modal>
  );
};

CommonModal.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
};
