import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { getRights } from "../../api/right";
import { LabeledRow } from "./../common/LabeledRow";
import { UserFormFields } from "./UserFormFields";

export const TtkInviteFormFields = ({ formik }) => {
  const [t] = useTranslation();
  const rights = getRights();

  return (
    <>
      <UserFormFields formik={formik} />
      <LabeledRow label={t("rights.title")}>
        {rights.map(({ attributes }) => {
          const { name } = attributes;
          const nme = `rights[${attributes.name}]`;
          return (
            <label key={nme}>
              <input
                type="checkbox"
                name="rights"
                onChange={formik.handleChange}
                value={name}
              />
              &nbsp;
              {t(`rights.${name}`)} &nbsp;
            </label>
          );
        })}
      </LabeledRow>
    </>
  );
};

TtkInviteFormFields.propTypes = {
  formik: PropTypes.object,
  withEmail: PropTypes.bool,
};
