import React from "react";
import { useTranslation } from "react-i18next";
import { validateDate } from "../../dateFunctions";

const dateOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  year: "numeric",
};

export const EventStartEnd = ({ start, end }) => {
  const [t] = useTranslation();

  const startDate = validateDate(start);
  const endDate = validateDate(end);
  return (
    <>
      {t("start")}: &nbsp;
      {startDate.toLocaleDateString(navigator.language, dateOptions)}
      <br />
      {t("end")}: &nbsp;
      {endDate.toLocaleDateString(navigator.language, dateOptions)}
    </>
  );
};
