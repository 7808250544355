import React from "react";
import { useTranslation } from "react-i18next";
import appConfig from "../../appConfig";
import { DashboardElement } from "./DashboardElement";
import { FilteredEventList } from "./FilteredEventList";

const NewAppointments = () => {
  const [t] = useTranslation();

  const { appointment_quick_accept } = appConfig;

  let title = t(
    appointment_quick_accept
      ? "appointments.to_enter_in_kas"
      : "appointments.new_requests"
  );

  const status = appointment_quick_accept ? "accepted" : "requested";

  return (
    <DashboardElement title={title} icon="calendar-plus" boxColor="gras-green">
      <FilteredEventList params={{ status, entered: false }} />
    </DashboardElement>
  );
};

export default NewAppointments;
