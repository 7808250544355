import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEvents } from "../../api/event";
import { EventList } from "./EventList";
import { CalendarTitle } from "../common/CalendarTitle";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { sortByCalendars } from "../../shared";
import { ModalID } from "../../constants";
import { CalendarLink } from "../common/CalendarLink";

export const FilteredEventList = ({ params }) => {
  const { calendars } = useSelector((state) => state.dashboard);
  const { showModal, remoteEventUpdates } = useSelector(
    (state) => state.appState
  );

  const [list, setList] = useState([]);

  const reloadEvents = () => {
    getEvents(params).then((response) => {
      setList(sortByCalendars(response.data.data));
    });
  };

  useEffect(() => {
    if (!showModal.includes(ModalID.EVENT)) {
      reloadEvents();
    }
  }, [showModal]);

  useEffect(() => {
    if (remoteEventUpdates?.length) reloadEvents();
  }, [remoteEventUpdates]);

  const defaultKey = Object.keys(list)[0];

  return (
    <Accordion defaultActiveKey={defaultKey}>
      {Object.keys(list).map((key) => {
        const entries = list[key];
        const calendar = calendars.find((cal) => cal.id == key);
        if (!calendar) return null;
        const calendarKey = `cal-link-${calendar.id}`;
        return (
          <Card key={calendarKey}>
            <Accordion.Toggle as={Card.Header} eventKey={key}>
              <CalendarTitle
                title={calendar.attributes.title}
                count={entries.length}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={key}>
              <EventList events={entries}>
                <CalendarLink id={calendar.id} />
              </EventList>
            </Accordion.Collapse>
          </Card>
        );
      })}
    </Accordion>
  );
};
