import React, { useEffect, useState } from "react";
import { emptyStorage, logIn } from "../../api/user";
import { useDispatch } from "react-redux";
import { updateAuth } from "../../actions/app";
import { NotificationLayer } from "../common/NotificationLayer";
import { Link } from "react-router-dom";
import { LabeledRow } from "../common/LabeledRow";
import { useTranslation } from "react-i18next";
import { PasswordResetStatus } from "../../constants";
import { updateCurrentUser } from "../../actions/dashboard";
import { setCookie, verifyCredentials } from "../../shared";
import { storeAuthInLocalStorage } from "../../actions/encryption";
import appConfig from "../../appConfig";
import { Button, Col, Row } from "react-bootstrap";
import { AnsbUser, ApiResponse } from "../../types";

function LoginForm() {
  const { debug } = appConfig;
  const [email, setEmail] = useState(debug ? "@it-ref.de" : "");
  const [password, setPassword] = useState(debug ? "1Passwort!" : "");
  const [authentication, setAuthentication] = useState(
    debug ? "1Passwort!" : ""
  );
  const [apiResponse, setApiResponse] = useState<ApiResponse>(null);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const inPwReset = (user: AnsbUser): boolean => {
    return (
      user.password_reset_status === PasswordResetStatus.SET_ON_DREAMIN_FORT
    );
  };

  const submitForm = () => {
    setApiResponse(null);

    logIn({ email, password })
      .then((user: AnsbUser) => {
        if (inPwReset(user)) {
          setApiResponse({ status: 400, statusText: t("login.pw_reset") });
          emptyStorage();
          return;
        }
        verifyCredentials(user, password, authentication)
          .then(() => {
            setCookie("inSession", true);
            storeAuthInLocalStorage(password, authentication);
            dispatch(updateAuth(authentication, password));
            dispatch(updateCurrentUser(user));
            let notice = document.getElementById("notice");
            if (notice) notice.remove();
          })
          .catch(setError);
      })
      .catch(setError);
  };

  const setError = (response: ApiResponse) => {
    emptyStorage()
    setApiResponse({
      status: response?.status,
      message: t("login.standard_error"),
    });
  };

  return (
    <Col md={8}>
      <h2>
        <>{t("login.login_noun")}</>
      </h2>
      <p>{t("login.introduction_text")}</p>
      <form>
        <LabeledRow label={t("email")} htmlFor="ansb_user_email">
          <input
            className="string email optional form-control"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            name="ansb_user[email]"
            id="ansb_user_email"
            autoComplete="username"
          />
        </LabeledRow>
        <LabeledRow label={t("password")} htmlFor="ansb_user_password">
          <input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="password optional form-control"
            type="password"
            autoComplete="current-password"
            id="ansb_user_password"
          />
        </LabeledRow>
        <LabeledRow label={t("auth_code")} htmlFor="ansb_user_auth">
          <input
            className="password optional form-control"
            type="password"
            autoComplete="off"
            id="ansb_user_auth"
            value={authentication}
            onChange={(e) => {
              setAuthentication(e.target.value);
            }}
          />
        </LabeledRow>
        <Button
          variant="primary"
          onClick={(e) => {
            e.preventDefault(), submitForm();
          }}
          type="submit"
          className="ghost-button overlay-button"
        >
          {t("sign_in")}
        </Button>
        <NotificationLayer
          apiResponse={apiResponse}
          customTrigger={apiResponse?.status}
        />
      </form>
      <Row>
        <Link to="/forgot_password">{t("login.forgot_password")}</Link>
      </Row>
      <Row className="mt-4">
        <Col>
          <h2>{t("sign_up.header")}</h2>
          {t("login.sign_up_desc")}&nbsp;
          <Link to={"/sign_up"}>{t("login.sign_up_action")}</Link>
        </Col>
      </Row>
    </Col>
  );
}

export default LoginForm;
