import React from "react";
import {
  displayHourMinute,
  displayDateDayMonth,
  validateDate,
} from "../../dateFunctions";
import { openEventModal } from "../../shared";

export const EventElement = ({ details }) => {
  const startDate = validateDate(details.start);

  const oem = (e) => openEventModal(details);
  return (
    <div className="event-element" onClick={oem}>
      <h3>{displayDateDayMonth(startDate)}</h3>
      <p>{displayHourMinute(startDate)}</p>
    </div>
  );
};
