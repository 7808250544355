import React from "react";
import { useTranslation } from "react-i18next";
import { DashboardElement } from "./DashboardElement";
import { FilteredEventList } from "./FilteredEventList";

const AllEvents = () => {
  const [t] = useTranslation();

  return (
    <DashboardElement
      boxColor="gras-green"
      title={t("event.all_events")}
      icon="calendar"
    >
      <FilteredEventList params={{ limit: 50 }} />
    </DashboardElement>
  );
};

export default AllEvents;
