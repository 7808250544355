import { t } from "i18next";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import { checkForUserInSession, logOut } from "../api/user";
import Profil from "../components/Profil";
import { UserRole } from "../constants";
import CalendarIndex from "./calendar/CalendarIndex";
import CalendarShow from "./calendar/CalendarShow";
import { ContentContainer } from "./common/ContentContainer";
import Dashboard from "./Dashboard";
import { EventSeriesIndex } from "./event_series/EventSeriesIndex";
import FileIndex from "./files/FileIndex";
import { FileTransfer } from "./files/FileTransfer";
import { InviteForm } from "./htk/InviteForm";
import TtkIndex from "./htk/TtkIndex";
import ForgotPassword from "./login/ForgotPassword";
import MemberMap from "./MemberMap";
import MemberOverview from "./MemberOverview";
import PageContainer from "./PageContainer";
import { RootView } from "./RootView";
import { SignupForm } from "./SignupForm";

export function Routing() {
  useEffect(() => {
    checkForUserInSession()
      .then((res) => {
        if (res.data.data.attributes.user_role == UserRole.WAA) logOut();
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <PageContainer>
      <Routes>
        <Route exact path="/" element={<RootView />} />
        <Route exact path="/login" element={<RootView />} />
        <Route exact path="/member_map" element={<MemberMap />} />
        <Route exact path="/sign_up" element={<SignupForm />} />
        <Route exact path="/forgot_password" element={<ForgotPassword />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/calendars" element={<CalendarIndex />}>
          <Route exact path="create" element={<CalendarIndex />} />
          <Route path=":id" element={<CalendarShow />} />
        </Route>
        <Route path="/ttks" element={<Outlet />}>
          <Route
            exact
            path="invite"
            element={
              <ContentContainer title={t("ttk_overview")}>
                <Row>
                  <Col md={3}>
                    <Link to="/ttks">&laquo; {t("back_to_overview")}</Link>
                  </Col>
                  <Col md={9}>
                    <InviteForm />
                  </Col>
                </Row>
              </ContentContainer>
            }
          />
          <Route path="" element={<TtkIndex />} />
        </Route>
        <Route path="/files" element={<Outlet />}>
          <Route exact path="" element={<FileIndex />} />
          <Route path=":userId" element={<FileTransfer />} />
        </Route>
        <Route path="/profil" element={<Profil />} />
        <Route exact path="/event_series" element={<EventSeriesIndex />}>
          <Route path=":series_id" element={<EventSeriesIndex />} />
        </Route>
        <Route path="/members" element={<MemberOverview />} />
      </Routes>
    </PageContainer>
  );
}

export default Routing;
