import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";

export const ContentContainer = ({ title, children, backlink }) => {
  const [t] = useTranslation();
  return (
    <Col md={12}>
      {backlink || (
        <Link to={`/dashboard`} className="small">
          &laquo; {t("back_to_dashboard")}
        </Link>
      )}
      <h1>{title}</h1>
      {children}
    </Col>
  );
};

ContentContainer.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  backlink: PropTypes.object,
};
