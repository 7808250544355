import React from "react";
import PropTypes from "prop-types";
import { useContext } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";

export function ContextAwareToggle({
  children,
  eventKey,
  callback,
  className,
}) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className={`rowflex space-between ${className} accordion-toggle ${
        isCurrentEventKey ? "accordion-toggle-selected" : ""
      }`}
      onClick={decoratedOnClick}
    >
      {children}

      <span className="float-right">
        <i className={`fa fa-caret-${isCurrentEventKey ? "up" : "down"}`}></i>
      </span>
    </div>
  );
}

ContextAwareToggle.propTypes = {
  callback: PropTypes.func,
  children: PropTypes.any,
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};
