import React from "react";
import { useCurrentUser } from "../../custom_hooks/user";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";
import { changedPassword, logOut, updateUser } from "../../api/user";
import { createKeyPair } from "../../actions/encryption";
import { CommonForm } from "../common/CommonForm";
import { UpdatePasswordFormFields } from "../forms/UpdatePasswordFormFields";

function UpdatePassword() {
  const currentUser = useCurrentUser();
  const [t] = useTranslation();

  const magicPasswordPattern =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  const changePasswordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(magicPasswordPattern, t("reset_password.pw_pattern"))
      .required(t("validation.field_required", { field: t("password") })),
    passwordConfirmation: Yup.string()
      .equals([Yup.ref("password")], t("reset_password.pw_must_match"))
      .ensure()
      .when("password", {
        is: (val) => val && val.length > 0,
        then: Yup.string().required(
          t("validation.field_required", { field: t("password_confirmation") })
        ),
      }),
    authCode: Yup.string()
      .matches(magicPasswordPattern, t("reset_password.auth_pattern"))
      .required(t("validation.field_required", { field: t("auth_code") }))
      .notOneOf(
        [Yup.ref("password"), null],
        t("reset_password.pw_and_auth_cannot_be_the_same")
      ),
    authCodeConfirmation: Yup.string()
      .equals([Yup.ref("authCode")], t("reset_password.auth_must_match"))
      .ensure()
      .when("authCode", {
        is: (val) => val && val.length > 0,
        then: Yup.string().required(
          t("validation.field_required", {
            field: t("reset_password.auth_code_confirmation"),
          })
        ),
      }),
  });

  const submitPasswordChange = (values) => {
    const { id } = currentUser;

    return new Promise((resolve, reject) => {
      // trigger window.beforeunload
      const { privateKey, publicKey } = createKeyPair(
        values.password,
        values.authCode
      );
      updateUser(
        {
          private_key: privateKey,
          public_key: publicKey,
          password: values.password,
        },
        id
      )
        .then(() => {
          changedPassword(id)
            .then(() => {
              // resolve .beforeunload
              logOut();
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

  if (!currentUser) return <CommonSpinner />;

  return (
    <div>
      <CommonForm
        translationScope="reset_password"
        apiEndpoint={submitPasswordChange}
        initialValues={{
          password: "",
          passwordConfirmation: "",
          authCode: "",
          authCodeConfirmation: "",
        }}
        fieldsComponent={UpdatePasswordFormFields}
        validationSchema={changePasswordValidationSchema}
      />
    </div>
  );
}

export default UpdatePassword;
