import PropTypes from "prop-types";
import React, { useState } from "react";
import { declineNewMember } from "../../api/user";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { showConfirmationModal } from "../../actions/app";

export default function HtkNewMembersDecline({ id }) {
  const [saving, setSaving] = useState(false);
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const toggleModal = () => {
    dispatch(
      showConfirmationModal({
        title: t("members.new_members.reject"),
        description: t(`members.new_members.reject_confirm`),
        viewComponent,
      })
    );
  };

  const onDeclineClick = () => {
    setSaving(true);

    declineNewMember(id).then((res) => {
      setSaving(false);
      toggleModal();
    });
  };

  const viewComponent = (
    <div className="rowflex space-between">
      <Button variant="danger" disabled={saving} onClick={onDeclineClick}>
        {t("members.new_members.yes_reject")}
      </Button>
      <Button variant="secondary" onClick={toggleModal}>
        {t("cancel")}
      </Button>
    </div>
  );

  return (
    <Button variant="danger" onClick={toggleModal}>
      {t("reject")}
    </Button>
  );
}

HtkNewMembersDecline.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
