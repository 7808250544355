import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAppointmentContent } from "../actions/decryption";
import { getCalendar } from "../api/calendar";
import { getAppointmentForEvent } from "../api/event";
import { EventStatus } from "../eventStatus";
import { validateDate } from "../dateFunctions";
import { useCurrentUser } from "./user";

/**
 * Get list of events for calendar_id
 * update timestamp in lastUpdate to re-trigger load
 * @param {number} id
 * @param {number} lastUpdate
 */
export const useEvents = (calendar_id, lastUpdate) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!calendar_id) {
      setData([]);
      return;
    }
    getCalendar(calendar_id)
      .then((calendar) => {
        const eventList = calendar.data.data.attributes.events;
        eventList.forEach((event) => {
          event.start = validateDate(event.start);
          event.end = validateDate(event.end);
        });
        setData(eventList);
      })
      .catch(console.warn);
  }, [calendar_id, lastUpdate]);

  return data;
};

/**
 * Shorthand for redux currentEvent element
 */
export const useCurrentEvent = () => {
  const { currentEvent } = useSelector((state) => state.appState);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    setEvent(currentEvent);
  }, [currentEvent]);

  return event;
};

export const useCurrentAppointment = () => {
  const currentEvent = useCurrentEvent();
  const currentUser = useCurrentUser();
  const [appointment, setAppointment] = useState(null);

  useEffect(() => {
    if (!currentEvent || !currentUser) return;
    if (currentEvent.status === EventStatus.CREATED) return setAppointment({});

    getAppointmentForEvent(currentEvent.id).then((res) => {
      const content = getAppointmentContent(res.data.data, currentUser.id);
      setAppointment(content);
    });
  }, [currentEvent]);

  return appointment;
};
