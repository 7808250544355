import React from "react";
import { useTranslation } from "react-i18next";
import { LabeledRow } from "./../common/LabeledRow";

export const LocationFormFields = ({ formik }) => {
  const [t] = useTranslation();

  const checkboxChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.checked);
  };

  return (
    <>
      <LabeledRow label={t("location.clinic_name")} required={true}>
        <input
          defaultValue={formik.values.clinic_name}
          name="clinic_name"
          className="form-control"
          onChange={formik.handleChange}
        />
      </LabeledRow>
      <LabeledRow label={t("location.clinic_email")}>
        <input
          defaultValue={formik.values.clinic_email}
          name="clinic_email"
          className="form-control"
          onChange={formik.handleChange}
        />
      </LabeledRow>
      <LabeledRow label={t("location.website")}>
        <input
          defaultValue={formik.values.website}
          name="website"
          className="form-control"
          onChange={formik.handleChange}
        />
      </LabeledRow>
      <LabeledRow label={t("location.street")} required={true}>
        <input
          defaultValue={formik.values.street}
          name="street"
          className="form-control"
          onChange={formik.handleChange}
        />
      </LabeledRow>
      <LabeledRow label={t("location.postal_code")} required={true}>
        <input
          defaultValue={formik.values.postal_code}
          name="postal_code"
          className="form-control"
          onChange={formik.handleChange}
        />
      </LabeledRow>
      <LabeledRow label={t("location.city")} required={true}>
        <input
          defaultValue={formik.values.city}
          name="city"
          className="form-control"
          onChange={formik.handleChange}
        />
      </LabeledRow>
      <LabeledRow label={t("phone")} required={true}>
        <input
          defaultValue={formik.values.phone}
          name="phone"
          className="form-control"
          onChange={formik.handleChange}
        />
      </LabeledRow>
      <LabeledRow label={t("location.fax")} required={false}>
        <input
          defaultValue={formik.values.fax}
          name="fax"
          className="form-control"
          onChange={formik.handleChange}
        />
      </LabeledRow>
      <LabeledRow label={t("location.show_in_map")}>
        <input
          type="checkbox"
          name="show_in_map"
          onClick={checkboxChange}
          defaultChecked={formik.values.show_in_map}
        />
      </LabeledRow>
      <LabeledRow label={t("location.show_contact_details")} required={false}>
        <input
          type="checkbox"
          name="show_contact_details"
          onClick={checkboxChange}
          defaultChecked={formik.values.show_contact_details}
        />
      </LabeledRow>
    </>
  );
};
