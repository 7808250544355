import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { CommonModal } from "./CommonModal";
import { useSelector } from "react-redux";
import { ModalID } from "../../constants";

/**
 * To open, dispatch showConfirmationModal with object params
 * title, description, proceed
 * and optionally viewComponent
 *
 * Shows a confirmation modal and executes proceed function
 * upon user action with either true (when confirmed) or false (when canceled)
 *
 * @returns {component}
 */
export const ConfirmationModal = () => {
  const [t] = useTranslation();

  const { modalDetails } = useSelector((state) => state.appState);

  if (!modalDetails[ModalID.CONFIRMATION]) return null;

  const { title, description, viewComponent, proceed } =
    modalDetails[ModalID.CONFIRMATION];

  const proceedOK = () => proceed(true);
  const proceedCancel = () => proceed(false);

  return (
    <CommonModal title={title} modalId={ModalID.CONFIRMATION}>
      {description}
      {viewComponent ? (
        viewComponent
      ) : (
        <div className="rowflex space-between padding-top-10">
          <Button variant="primary" onClick={proceedOK}>
            {t("confirm")}
          </Button>
          <Button variant="secondary" onClick={proceedCancel}>
            {t("cancel")}
          </Button>
        </div>
      )}
    </CommonModal>
  );
};

export default ConfirmationModal;
