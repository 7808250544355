import React from "react";
import PropTypes from "prop-types";
export const CustomInput = ({ name, formik, type }) => {
  const updateValue = (e) => {
    formik.setFieldValue(name, e.currentTarget.value);
  };

  if (type == "textarea") {
    return (
      <textarea
        className="form-control"
        name={name}
        rows={3}
        onChange={updateValue}
      >
        {formik.values[name]}
      </textarea>
    );
  } else {
    return (
      <input
        className="form-control"
        onChange={updateValue}
        name={name}
        defaultValue={formik.values[name]}
      />
    );
  }
};

CustomInput.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["text", "textarea"]),
};
CustomInput.defaultProps = {
  type: "text",
};
