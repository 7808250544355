export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const UPDATE_APPOINTMENT_SUCCESSFUL = "UUPDATE_APPOINTMENT_SUCCESSFUL";
export const UPDATE_APPOINTMENT_FAILED = "UUPDATE_APPOINTMENT_FAILED";

export const CREATE_NEW_APPOINTMENTS = "CREATE_NEW_APPOINTMENTS";
export const CREATE_NEW_APPOINTMENTS_SUCCESSFUL =
  "CREATE_NEW_APPOINTMENTS_SUCCESSFUL";
export const CREATE_NEW_APPOINTMENTS_FAILED = "CREATE_NEW_APPOINTMENTS_FAILED";

import forge from "node-forge";

export const createKeyPair = (password, auth) => {
  const secret = password + auth;
  const newKeys = forge.pki.rsa.generateKeyPair(2048);
  const privateKeyPem = forge.pki.encryptRsaPrivateKey(
    newKeys.privateKey,
    secret
  );
  return {
    privateKey: privateKeyPem,
    publicKey: forge.pki.publicKeyToPem(newKeys.publicKey),
  };
};

export const encryptAppointment = (data) => {
  if (!data.decrypted_sym_key || !data.appointment_iv) {
    console.error("cannot update appointment because key and iv missing");
    return null;
  }

  const key = data.decrypted_sym_key;
  const iv = forge.util.decode64(data.appointment_iv);
  const cipher = forge.cipher.createCipher("AES-CBC", key);
  const content = getJsonContent(data);
  cipher.start({
    iv: iv,
  });
  cipher.update(forge.util.createBuffer(content));
  cipher.finish();
  const encrypted = forge.util.encode64(cipher.output.data);
  return encrypted;
};

export const createAppointmentEncryptionObj = (content) => {
  const symKey = forge.random.getBytesSync(16);
  const iv = forge.random.getBytesSync(16);
  const cipher = forge.cipher.createCipher("AES-CBC", symKey);
  cipher.start({
    iv: iv,
  });
  cipher.update(forge.util.createBuffer(content));
  cipher.finish();
  const encryptedContent = forge.util.encode64(cipher.output.data);
  return {
    content: encryptedContent,
    iv: iv,
    symKey: symKey,
  };
};

export const storeAuthInLocalStorage = (pw, auth) => {
  const salt = forge.random.getBytesSync(128);
  const key = forge.pkcs5.pbkdf2(auth, salt, 4, 16);
  const iv = forge.random.getBytesSync(16);
  const cipher = forge.cipher.createCipher("AES-CBC", key);
  cipher.start({ iv: iv });
  cipher.update(forge.util.createBuffer(pw));
  cipher.finish();
  const cipherText = forge.util.encode64(cipher.output.getBytes());
  const obj = {
    cipher_text: cipherText,
    salt: forge.util.encode64(salt),
    iv: forge.util.encode64(iv),
  };
  window.localStorage.setItem("obj", JSON.stringify(obj));
};

/**
 * Returns string of data whilst deleting decrypted_sym_key and appointment_iv
 * @param {object} data 
 * @returns string
 */
export const getJsonContent = (data) => {
  const limitedData = {...data}
  delete limitedData.decrypted_sym_key
  delete limitedData.appointment_iv
  return JSON.stringify(limitedData);
}
