import React from "react";
import { useTranslation } from "react-i18next";
import { LabeledRow } from "./../common/LabeledRow";

export const EmailFormFields = ({ formik }) => {
  const [t] = useTranslation();

  return (
    <>
      <LabeledRow label={t("email")} required={true}>
        <input
          name="email"
          type="email"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
      </LabeledRow>
      <LabeledRow label={t("location.email_confirmation")} required={true}>
        <input
          name="emailConfirmation"
          type="email"
          className="form-control"
          onChange={formik.handleChange}
          value={formik.values.emailConfirmation}
        />
      </LabeledRow>
    </>
  );
};
