import React from "react";
import { Col, Row } from "react-bootstrap";
import { ActionButton } from "./ActionButton";
import { RetractButton } from "./RetractButton";

export function AppointmentCancelOptions({ onCancel, onRetract }) {
  return (
    <div>
      <h4>Ablehnung Patiententermin</h4>
      <Row>
        <Col md={12}>
          <RetractButton onClick={onRetract} />
          <ActionButton
            desc="appointments.cancellation_description"
            title="appointments.cancel_booking"
            onClick={onCancel}
          />
        </Col>
      </Row>
    </div>
  );
}
