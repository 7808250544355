import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { deleteUser } from "../../api/user";
import { useTranslation } from "react-i18next";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";

export default function MemberDeleteModal({ show, onHide, selectedUserId }) {
  const [reason, setReason] = useState("");
  const [t] = useTranslation();
  const [updating, setUpdating] = useState(false);

  const handleDelete = (id, reason) => {
    setUpdating(true);
    deleteUser(id, reason).then(() => {
      setUpdating(false);
      onHide();
    });
  };

  return (
    <Modal animation={false} centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <h2>{t("member_overview.delete_modal.header")}</h2>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body">
          <Row>
            <p>{t("member_overview.delete_modal.reason")}</p>
            <textarea
              onChange={(e) => setReason(e.target.value)}
              id="reason_delete"
              rows="5"
              cols="70"
              type="text_area"
              defaultValue={reason}
            ></textarea>
          </Row>
          <div className="row space-between">
            <Button
              disabled={reason.length < 1 || updating}
              variant={reason.length < 1 ? "danger" : "success"}
              onClick={() => handleDelete(selectedUserId, reason)}
            >
              {t("member_overview.yes_delete")}
            </Button>

            {updating && <CommonSpinner />}

            <Button
              className="pull-right"
              variant="secondary"
              onClick={() => onHide()}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
