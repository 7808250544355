import * as React from "react";
import { useEffect } from "react";
import { getCalendars } from "../api/calendar";
import HtkDashboardIndex from "../components/dashboard/HtkDashboardIndex";
import NaaDashboardIndex from "./dashboard/NaaDashboardIndex";
import { UserRole } from "../constants";
import { useUserRole } from "../custom_hooks/user";
import { store } from "../store/store";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";
import TtkDashboardIndex from "./dashboard/TtkDashboardIndex";

function Dashboard() {
  const role = useUserRole();

  useEffect(() => {
    const { dashboard } = store.getState();
    if (dashboard && !dashboard.calendars && !dashboard.calendars.length) {
      getCalendars();
    }
  }, [role]);

  if (!role) return <CommonSpinner />;

  switch (role) {
    case UserRole.HTK:
      return <HtkDashboardIndex />;
    case UserRole.TTK:
      return <TtkDashboardIndex />;
    default:
      return <NaaDashboardIndex />;
  }
}

export default Dashboard;
