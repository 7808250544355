import * as React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmptyObject } from "../../shared";
import { Alert } from "react-bootstrap";

export const NotificationLayer = ({
  apiResponse = null,
  customTrigger,
  message,
  className,
  children = "",
}) => {
  const [acknowledged, setAcknowledged] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    setAcknowledged(false);
  }, [customTrigger, apiResponse]);

  if (acknowledged || !customTrigger) return null;

  const errorClass = () => {
    if (!isEmptyObject(apiResponse)) return apiErrorClass;
    return className;
  };

  if (!apiResponse) return null;

  const variant = apiResponse.status === 200 ? "success" : "danger";

  return (
    <Alert variant={variant}>
      {apiResponse.message || apiResponse.statusText || message}
      {children}
      <div
        className="cursor-pointer float-right"
        onClick={() => setAcknowledged(true)}
      >
        <i title={t("close")} type="far" className="fa fa-times"></i>
      </div>
    </Alert>
  );
};

NotificationLayer.propTypes = {
  apiResponse: PropTypes.object,
  customTrigger: PropTypes.any,
  message: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};
