import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UserRole } from "../../constants";
import { useUserRole } from "../../custom_hooks/user";
import { AnsbUser, ReduxState } from "../../types";
import { Informant } from "../common/Informant";
import { updateTtks } from "../../actions/app";
import { getTtks } from "../../api/user";

interface PropDefs {
  ownerDetails: AnsbUser;
}

export const Informers = ({ ownerDetails }: PropDefs) => {
  const { ttks } = useSelector((state: ReduxState) => state.appState);
  const [t] = useTranslation();
  const role = useUserRole();
  const dispatch = useDispatch();


  useEffect(() => {
    if([UserRole.HTK, UserRole.TTK].includes(role)) {
      getTtks().then((users: { data: { data:  AnsbUser[] } }) => {
        dispatch(updateTtks(users.data.data));
      });
    }
  }, [role]);

  if (!role || !ownerDetails) return null;

  return (
    <div className="margin-bottom form-group">
      {role === UserRole.NAA ? (
        <Informant
          title="files.select_special_field"
          description="files.inform_desc"
          items={window.ITREF.special_fields}
          ownerDetails={ownerDetails}
          placeholder="files.inform_special_field_select"
        />
      ) : (
        <>
          <Informant
            title="files.inform_naa"
            description="files.inform_naa_desc"
            users={[ownerDetails]}
            ownerDetails={ownerDetails}
          />
          <hr />
          <Informant
            title="files.inform_ttk"
            description="files.inform_ttk_desc"
            users={ttks}
            placeholder="files.inform_ttk_select"
            ownerDetails={ownerDetails}
          />
        </>
      )}
    </div>
  );
};

export default Informers;
