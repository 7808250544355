import React from "react";
import PropTypes from "prop-types";
import { openEventModal } from "../../shared";
import { StatusIndicator } from "../common/StatusIndicator";

export const CommonEventView = ({
  event,
  location_title,
  applied_event_date_time,
  patient_name,
  status,
  children,
}) => {
  const triggerEventModal = () => {
    openEventModal(event);
  };

  return (
    <div className="event-list-item rowflex" onClick={triggerEventModal}>
      <div className="event-details rowflex space-between">
        <div>
          <div className="rowflex">
            <StatusIndicator status={status} />
            {applied_event_date_time}
          </div>
          <span className="small">
            {patient_name}
            {location_title && (
              <>
                <br />
                {location_title}
              </>
            )}
          </span>
        </div>

        {children}
      </div>
    </div>
  );
};

CommonEventView.propTypes = {
  applied_event_date_time: PropTypes.any,
  children: PropTypes.any,
  event: PropTypes.object.isRequired,
  location_title: PropTypes.string,
  patient_name: PropTypes.string,
  status: PropTypes.string,
};
