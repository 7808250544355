import React from "react";
import { useUsers } from "../../custom_hooks/user";
import { getDisplayName } from "../../shared";

export const CalendarPermissions = ({ permissionedUsers, onUpdate }) => {
  const [ttks] = useUsers(1, { user_role: "ttk", status: "member" });

  const toggleMember = (ttk, val) => {
    let newPermitted = [...permissionedUsers];
    if (val) {
      newPermitted.push(ttk);
    } else {
      newPermitted = permissionedUsers.filter((user) => user.id != ttk.id);
    }
    onUpdate(newPermitted);
  };

  const ttkChecker = (user) => {
    const fieldId = "fld" + user.id;
    const checked = !!permissionedUsers.find((perms) => perms.id == user.id);
    return (
      <li key={user.id}>
        <label htmlFor={fieldId}>
          <input
            type="checkbox"
            name={fieldId}
            id={fieldId}
            checked={checked}
            onChange={() => toggleMember(user, !checked)}
          />
          {getDisplayName(user.attributes)}
        </label>
      </li>
    );
  };

  return (
    <div>
      <p>{permissionedUsers?.length} TTKs</p>
      <ul className="permitted-users-list">{ttks.map(ttkChecker)}</ul>
    </div>
  );
};
