import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export function DashboardButton({ title, href, icon }) {
  return (
    <div className="dashboard-button">
      <div className="dashboard-head">
        <Link to={href} className="li">
          <i className={"fa fa-" + icon} />
          {title}
        </Link>
      </div>
    </div>
  );
}

DashboardButton.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
};
