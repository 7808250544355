import React from 'react'
import Select from '../forms/Select';

interface PropDefs {
  onChange: (value: number) => void
}
export const SpecialFieldSelect = ({ onChange }: PropDefs) => {
  const items = window.ITREF.special_fields;
  return <Select items={items} onChange={onChange} />
};

export default SpecialFieldSelect
