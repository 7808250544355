/**
 * Returns index of the weekday, where Monday returns 0
 * @param {date} someDate
 */
export const getWeekday = (someDate) => {
  return (someDate.getDay() + 6) % 7;
};

const defaultDateOptions = {
  day: "numeric",
  month: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

/**
 * Formats date into locale-specific string with either day, month, hour, minute
 * or any other options passed
 * @param {date} date
 * @param {options} DateOptions
 */
export const displayDate = (date, options) => {
  try {
    return new Intl.DateTimeFormat(
      navigator.language,
      options || defaultDateOptions
    ).format(date);
  } catch (e) {
    return e.message;
  }
};

/**
 * Formats date into locale-specific string with month and year
 * @param {date} date
 */
export const displayDateMonthYear = (date) => {
  return displayDate(date, { month: "numeric", year: "numeric" });
};

/**
 * Display day and month
 * @param {date} date
 */
export const displayDateDayMonth = (date) => {
  return displayDate(date, { month: "numeric", day: "numeric" });
};
/**
 * Display hour and minute
 * @param {date} date
 */
export const displayHourMinute = (date) => {
  return displayDate(date, { hour: "numeric", minute: "numeric" });
};

/**
 * Formats date into locale-specific string for day
 * @param {date} date
 */
export const displayDay = (date) => {
  return displayDate(date, { day: "numeric" });
};

/**
 * Get array of selected dates with correct start time
 * @param {object} values
 * @param {array} selectedDays
 * @returns Array of dates
 */
export const getActiveDates = (values, selectedDays) => {
  if (!selectedDays) return [];
  const { runtime_end, runtime_start, repetition } = values;

  let activeDays = [];

  const runtimeDayLastSecond = new Date(runtime_end);
  runtimeDayLastSecond.setHours(23, 59, 59);
  const today = new Date();
  let currentDay = new Date(runtime_start);
  if (currentDay < today) {
    currentDay = today;
  }
  currentDay.setHours(values.start_hour);
  currentDay.setMinutes(values.start_minute);

  if (selectedDays.length) {
    // runs through every day from runtime_start until runtimeDayLastSecond
    // and adds the ones matching the indexes in selectedDays into activeDays
    while (currentDay.getTime() <= runtimeDayLastSecond.getTime()) {
      if (selectedDays.includes(dayIndex(repetition, currentDay))) {
        activeDays.push(new Date(currentDay));
      }
      currentDay.setDate(currentDay.getDate() + 1);
    }
  }
  return activeDays;
};

/**
 * When repetition = weekly, returns index for 0=Monday up to 6=Sunday
 * When repetition = monthly, returns the numeric day (-1) of the month
 * @param {string} repetition
 * @param {date} currentDay
 */
const dayIndex = (repetition, currentDay) => {
  return repetition === "weekly"
    ? getWeekday(currentDay)
    : currentDay.getDate() - 1;
};

/**
 * Converts a string to date or just returns the given date
 * @param {string|date} date
 * @returns date
 */
export const validateDate = (date) => {
  return new Date(date);
};

/**
 * Check if d1 is before d2 (exclude equal date)
 * @param {date} d1
 * @param {date} d2
 * @param {boolean} considerTime
 * @returns boolean
 */
export const before = (d1, d2, considerTime) => {
  return (
    considerTime ? (d1 < d2) :
      (d1.getFullYear() < d2.getFullYear()) ||
      (d1.getMonth() < d2.getMonth()) ||
      (d1.getDate() < d2.getDate())
  );
};

/**
 * Check if d1 is on or after d2
 * @param {date} d1
 * @param {date} d2
 * @param {boolean} considerTime
 * @returns boolean
 */
export const onOrAfter = (d1, d2, considerTime) => {
  return (
    !before(d1, d2, considerTime)
  );
};

/**
 * Check if two dates are on the same day
 * @param {date} d1
 * @param {date} d2
 */
export const sameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

/**
 * checks if a date is in the past, not considering hours
 * @param {date} start
 * @returns boolean
 */
export function isPast(date) {
  let now = new Date();
  now.setHours(0, 0, 0, 0)
  return date.getTime() < now.getTime()
}

/**
 * Checks whether timeslots of newEvents have overlaps with givenEvents
 * @param {array} newEvents
 * @param {array} givenEvents
 * @returns arrays with valid and conflicting events
 */
export const validateNewEventTimes = (newEvents, givenEvents) => {
  let conflictingEvents = [];
  let validEvents = [];
  let isValid;

  newEvents.forEach((newEvent) => {
    isValid = true;
    for (let index = 0; index < givenEvents.length; index++) {
      const { start, end } = givenEvents[index];
      if (newEvent.end >= start && newEvent.start < end) {
        isValid = false;
        break;
      }
    }
    if (isValid && window.ITREF?.config?.holidays?.length) {
      for (
        let index = 0;
        index < window.ITREF.config.holidays.length;
        index++
      ) {
        const element = new Date(window.ITREF.config.holidays[index]);
        if (sameDay(element, newEvent.start)) {
          isValid = false;
          break;
        }
      }
    }
    isValid ? validEvents.push(newEvent) : conflictingEvents.push(newEvent);
  });

  return [validEvents, conflictingEvents];
};

export const dateToEventObject = (date, duration) => {
  date.setSeconds(0, 0);
  const response = {
    start: new Date(date),
    end: new Date(date),
  };
  response.end.setMinutes(response.start.getMinutes() + duration);
  return response;
};
