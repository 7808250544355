import React, { useState } from "react";
import { initializePasswordReset } from "../../api/user";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationLayer } from "../common/NotificationLayer";
import CommonSpinner from "@lmu-med/ci-components/dist/components/CommonSpinner";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { useEffect } from "react";
import appConfig from "../../appConfig";
import Alert from "react-bootstrap/Alert";
import { TrueApiResponseLayer } from "../common/TrueApiResponseLayer";

function ForgotPassword() {
  const [email, setEmail] = useState(appConfig.debug ? "@it-ref.de" : "");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [t] = useTranslation();

  const submitForm = () => {
    setLoading(true);
    setMessage("");

    initializePasswordReset(email)
      .then((res) => {
        setLoading(false);
        setMessage("forgot_password.flash_email_send");
        setStatus(200);
      })
      .catch((e) => {
        setLoading(false);
        setStatus(e.status);
        switch (e.status) {
          case 400:
            setMessage(e.statusText);
            break;
          case 409:
            setMessage("forgot_password.flash_already_requested");
            break;
          default:
            setMessage("forgot_password.flash_wrong_email_or_password");
            break;
        }
      });
  };

  useEffect(() => {
    if (email) setMessage("");
  }, [email]);

  return (
    <Col md={8}>
      <h1>{t("forgot_password.title")}</h1>
      <div>
        <p>{t("forgot_password.introduction_text")}</p>
      </div>
      <div className="overlay-content">
        <form className="simple_form new_ansb_user">
          <div className="input email optional ansb_user_email form-group">
            <label className="email optional input-head">E-Mail</label>
            <input
              className="string email optional form-control"
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name="ansb_user[email]"
              id="ansb_user_email"
            />
          </div>

          <CommonSpinner loading={loading} />

          {!status?.toString().startsWith("2") && (
            <Button
              onClick={(e) => {
                e.preventDefault(), submitForm();
              }}
              type="submit"
              name="commit"
              className="btn button ghost-button overlay-button"
            >
              {t("forgot_password.button_password_reset")}
            </Button>
          )}
        </form>

        {message && (
          <TrueApiResponseLayer message={t(message)} status={status} />
        )}
        <Link to="/">{t("forgot_password.button_to_login")}</Link>
      </div>
    </Col>
  );
}

export default ForgotPassword;
