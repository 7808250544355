import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { ActionButton } from "./ActionButton";
export function RetractButton({ onClick }) {
  return (
    <Row>
      <Col md={12} className="margin-bottom">
        <ActionButton onClick={onClick} title="appointments.reject_and_free" />
      </Col>
    </Row>
  );
}

RetractButton.propTypes = {
  onClick: PropTypes.func,
};
