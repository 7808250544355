import React from "react";
import { useTranslation } from "react-i18next";

export function Paragraph({ appointment, label }) {
  const [t] = useTranslation();

  if (!appointment[label]) return null;

  return (
    <p>
      {t(label)}: {appointment[label]}
    </p>
  );
}
