import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ModalID } from "../../constants";
import ax from "../../axios";
import { CommonForm } from "../common/CommonForm";
import { CommonModal } from "../common/CommonModal";
import { PatientDataFormFields } from "../forms/PatientDataFormFields";
import * as Yup from "yup";
import { useCurrentUser } from "../../custom_hooks/user";

export const PatientDataModal = () => {
  const [t] = useTranslation();
  const currentUser = useCurrentUser();

  const handleSubmit = (values) => {
    return ax.post(`/users/${currentUser.id}/patient_data.html`, values);
  };

  const requiredText = (field) => {
    return t("validation.field_required", {
      field: t(`patient_data.${field}`),
    });
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(requiredText("first_name")),
    last_name: Yup.string().required(requiredText("last_name")),
    birthday: Yup.string().required(requiredText("birthday")),
    email: Yup.string().required(requiredText("email")),
    phone: Yup.string().required(requiredText("phone")),
    patient_address: Yup.string().required(requiredText("patient_address")),
    insurance_type: Yup.string().required(t("validation.insurance_type")),
    insurance_with_number: Yup.string().required(
      t("validation.insurance_with_number")
    ),
    question: Yup.string().required(requiredText("question")),
  });

  return (
    <CommonModal
      title={t("files.append_patient_data")}
      modalId={ModalID.PATIENT_DATA}
      size="lg"
    >
      <CommonForm
        translationScope="patient_data"
        validationSchema={validationSchema}
        apiEndpoint={handleSubmit}
        initialValues={{ insurance_type: "statutory" }}
        fieldsComponent={PatientDataFormFields}
      >
        <Row>
          <Col sm={12} className="small">
            {t("validation.asterisks_optional")}
          </Col>
        </Row>
      </CommonForm>
    </CommonModal>
  );
};

export default PatientDataModal;
