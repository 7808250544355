import { useEffect, useState } from "react"
import ax from "../axios"

/**
 * Generic function to abstract away loading and error
 * @param path
 * @param method
 * @param params
 * @returns axios response
 */
export const useApiResponse = (path, method, params = null) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [myParams, setMyParams] = useState(null)

  useEffect(() => {
    if(!path) return

    let promise;
    setError(null)
    setLoading(true)

    if(method === 'post') {
      promise = ax.post(path, params)
    } else {
      let urlParams = params ? `?${new URLSearchParams(params).toString()}` : ''
      promise = ax.get(`${path + (path.includes(".json") ? '' : '.json')}${urlParams}`)
    }

    promise.then((res) => {
      setLoading(false)
      setResponse(res.data)
    })
    .catch((e) => {
      setLoading(false)
      setError(e)
    })
  }, [path, method, myParams])

  useEffect(() => {
    if(JSON.stringify(params) !== JSON.stringify(myParams)) {
      setMyParams(params)
    }
  }, [params])

  return [response, loading, error]
}
