import React from "react";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { updateEvent } from "../../api/event";
import { useCurrentEvent } from "../../custom_hooks/events";
import { EventStatus } from "../../eventStatus";

export const EnteredFlagSwitcher = ({ status }) => {
  const [value, setValue] = useState(false);
  const currentEvent = useCurrentEvent();
  const [t] = useTranslation();

  useEffect(() => {
    if (currentEvent) setValue(currentEvent.entered);
  }, [currentEvent]);

  const switchFlag = () => {
    updateEvent(currentEvent.id, { entered: !value });
    setValue(!value);
  };

  if (!currentEvent) return null;

  const displayValue = status === EventStatus.CANCELED ? !value : value;

  return (
    <Button variant={displayValue ? "primary" : "danger"} onClick={switchFlag}>
      <span>
        <i
          type="fas"
          className={`fa fa-${displayValue ? "toggle-on" : "toggle-off"}`}
        ></i>
      </span>
      &nbsp; {t(displayValue ? "entered_yes" : "entered_no")}
    </Button>
  );
};

EnteredFlagSwitcher.defaultProps = {
  defaultValue: false,
};
