import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Tooltip, OverlayTrigger, Button } from "react-bootstrap";

/**
 * Abstract away head clutter around dashboard content
 * @param {string} title | header text
 * @param {string} icon
 * @param {string} description | when set, displays a tooltip
 * @param {string} boxColor
 * @returns dashboard element component
 */
export const DashboardElement = ({
  title,
  icon,
  children,
  boxColor,
  description,
}) => {
  const renderPopover = (props) => {
    const newStyle = { opacity: 1 };
    Object.keys(props.style).forEach((kley) => {
      newStyle[kley] = props.style[kley];
    });

    return (
      <Tooltip id="button-tooltip" {...props} style={newStyle}>
        {description}
      </Tooltip>
    );
  };

  return (
    <Col lg={4}>
      <div className={`box-${boxColor} dashboard-element`}>
        <div className="dashboard-head">
          <h4 className="float-left">
            <i className={`fas fa-${icon} far-${icon}`}></i>
            &nbsp; {title}
          </h4>
          {description && (
            <OverlayTrigger overlay={renderPopover} transition={false}>
              <i className="fas fa-question-circle"></i>
            </OverlayTrigger>
          )}
        </div>
        <Row className={`dashboard-content box-${boxColor}`}>{children}</Row>
      </div>
    </Col>
  );
};

DashboardElement.defaultProps = {
  boxColor: "blue",
  title: "",
};
DashboardElement.propTypes = {
  boxColor: PropTypes.string,
  children: PropTypes.any,
  description: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default DashboardElement;
