import { useEffect, useState } from "react";
import { getCalendar } from "../api/calendar";

/**
 * Get details for calendar
 * update timestamp in lastUpdate to re-trigger load
 * @param {number} id
 * @param {number} lastUpdate
 */
export const useCalendar = (calendar_id, lastUpdate) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!calendar_id) {
      setData([]);
      return;
    }
    getCalendar(calendar_id)
      .then((calendar) => {
        setData(calendar.data.data);
      })
      .catch(console.warn);
  }, [calendar_id, lastUpdate]);

  return data;
};
