import ax from "../axios";

export const updateLocation = (values, locationId) => {
  return ax.patch(`/locations/${locationId}`, values);
};
export const createLocation = (values) => {
  return ax.post(`/locations`, values);
};
export const getLocations = () => {
  return ax.get(`/users/locations`);
};
