import PropTypes from "prop-types";
import React, { useState, useMemo } from "react";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select";
import { useTranslation } from "react-i18next";

function MapsSearch({ handleSearchResultClick }) {
  const prov = new OpenStreetMapProvider();
  const [results, setResults] = useState([]);
  const [selected, setSelected] = useState("");
  const [t] = useTranslation();

  const search = (query) => {
    if (query == "") return;
    prov.search({ query }).then(setResults);
  };

  const handleChange = (value) => {
    setSelected(value);
    handleSearchResultClick(value);
  };

  const debouncedSearch = useMemo(() => debounce(search, 500), []);

  return (
    <div className="ansb-location-search">
      <AsyncSelect
        // needed for showing the resultset in a higher layer than the actualmap
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        // end
        value={selected}
        noOptionsMessage={() => t("member_map.no_results")}
        isSearchable
        onInputChange={debouncedSearch}
        onChange={handleChange}
        options={results}
        placeholder={t("member_map.select_placeholder")}
      />
      <p>{t("member_map.explain_overview")}</p>
      <p>{t("member_map.explain_functionality")}</p>
    </div>
  );
}

MapsSearch.propTypes = {
  handleSearchResultClick: PropTypes.func,
};

export default MapsSearch;
