import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LabeledRow } from "./../common/LabeledRow";

export const CalendarFormFields = ({ formik }) => {
  const [t] = useTranslation();

  return (
    <>
      <LabeledRow label={t("calendar.create.title")} required={true}>
        <input
          name="title"
          className="form-control"
          onChange={formik.handleChange}
          defaultValue={formik.values.title}
        />
      </LabeledRow>
      <LabeledRow label={t("calendar.create.description")}>
        <input
          name="description"
          className="form-control"
          onChange={formik.handleChange}
          defaultValue={formik.values.description}
        />
      </LabeledRow>
      <LabeledRow label={t("calendar.auto_delete_events_prior")}>
        <input
          name="auto_delete_events_prior"
          className="form-control"
          onChange={formik.handleChange}
          defaultValue={formik.values.auto_delete_events_prior}
        />
      </LabeledRow>
    </>
  );
};

CalendarFormFields.propTypes = {
  formik: PropTypes.object,
};
