import React from "react";
import MemberMarker from "./MemberMarker";
import { latlngforMap } from "../../shared";

function MarkerLayer(props) {
  const { data, selectedIndex } = props;

  return data.map((item, index) => {
    const { geo_code } = item.attributes;
    return (
      <MemberMarker
        key={index}
        markerContent={item}
        center={latlngforMap(geo_code)}
        openPopup={selectedIndex === index}
      />
    );
  });
}

export default MarkerLayer;
